.sub-detail-head{
font-family: "Roboto" !important;
font-size: 14px;
font-weight: 500;
line-height: 16.41px;
letter-spacing: 0.5px;
color: #3c3c3c;
}
.sub-detail-data{
font-family: "Roboto" !important;
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
letter-spacing: 0.5px;
color: #818181;
}
.medical-proof-image img{
    width: 84px !important;
    border-radius: 12px !important;
    height: 105px !important;
    border: 1px solid #ececec !important;
}
.clinic-images img {
    width: 120px !important;
    height: 108px !important;
}
.clinic-images .p-image-preview-indicator{
    width: 120px !important;
    height: 108px !important;
}
.tttt .p-image-preview-container{
    object-fit: cover !important;
    border-radius: 20px !important;
    margin-right: 15px !important;
    width: 120px !important;
    height: 108px !important;
}