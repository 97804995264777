.accepted-status {
    background-color: #F4F6FA;
    border-radius: 4px;
    height: 24px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #62B2FD;
    margin-top: 8px;
    width: max-content;
    padding: 0px 8px 0px 8px;
    display: flex;
    align-items: center;
}

.pending-status {
    background-color: #F9F4FF;
    border-radius: 4px;
    height: 24px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #C288F6;
    margin-top: 8px;
    width: max-content;
    padding: 0px 8px 0px 8px;
    display: flex;
    align-items: center;
    width: max-content;
}

.denied-status {
    background-color: #FFF6EF;
    border-radius: 4px;
    height: 24px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #FD9A3D;
    margin-top: 8px;
    padding: 0px 8px 0px 8px;
    display: flex;
    align-items: center;
    width: max-content;
}

.btn_resend {
    background-color: #F5F5F5;
    border: none;
    padding: 8px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    letter-spacing: 0.25px;
    color: #3c3c3c;
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.consent-sent-timing {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #818181;
}

.dietitian-name-consent-head {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.dietitian-name-consent-value {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #818181;
    margin-bottom: 0px;
}