.submenu {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
  }
  
  /* Style for submenu items */
  .submenu a {
    display: flex;
    align-items: center;
    padding: 8px;
    text-decoration: none;
    color: #555;
  }
  
  /* Style for active submenu item */
  .submenu a.active {
    background-color: #ddd;
  }

  /* .rest-icon{
    background-image: url("../../../assets/icons/shop.svg");
  } */

.yeldam_nav_links .nav-link.active svg, .yeldam_nav_links .nav-link:hover svg {

    stroke: #36A41D !important; /* Change to your desired color */
}

.yeldam_nav_links .nav-link, .yeldam_nav_links .nav-link svg {

    stroke: #b0b0b0; /* Change to your desired color */
}
.submenu .nav-link{
    margin: 0 !important;
    border-radius: 0px !important;
    border-left: 2px solid #F5F5F5 !important;
}

.submenu .nav-link.active,.submenu .nav-link:hover{
    border-left: 2px solid #36A41D !important;
}

.submenu .nav-link.active, .submenu .nav-link:hover {
    background-color: #fff !important;
    color: #000 !important;
}

.submenu .nav-link.active span, .submenu .nav-link:hover span{
  background-color: #fff !important;
  color: #000 !important;
}