.pending-text {
    width: 65px;
    height: 22px;
    border-radius: 4px;
    padding: 4px 9px 4px 9px;
    background-color: #FFF4CE;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #AE8701;
}

.event-title-name span {
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
}

.date-text {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #818181;
}

.event-desc {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
}

.event-link {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #818181;
}

.approved-text {
    width: 71px;
    height: 22px;
    border-radius: 4px;
    background-color: #DFF6DD;
    padding: 4px 9px 4px 9px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #2BC04C;

}
.rejected-text{
    width: 71px;
    height: 22px;
    border-radius: 4px;
    background-color: #FED9CC;
    padding: 4px 9px 4px 9px;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #D83B01;
}
.agent-name p{
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #818181;
}
.agent-name span{
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px; 
    color: #3c3c3c;
}
.reason-txt{
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px; 
    color: #3c3c3c;
}
