.p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}
.p-datatable .p-datatable-thead > tr > th {
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 400 !important;
line-height: 16px !important;
letter-spacing: 0.5px !important;
color: #818181 !important;

}
.approved-table-heads .p-datatable-thead > tr > th {
    padding: 3px 5px !important;
    background-color: #f5f5f5 !important;
    /* color: var(--ym-text-primary) !important; */
}
