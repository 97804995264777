.timings-card {
    width: 300px;
    height: auto;
    background-color: #f5f5f5;
    border-radius: 4px;
}
.day{
/* font-family: "Roboto"; */
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.5px;
color:#3c3c3c;
}
.time{
    /* font-family: "Roboto"; */
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.5px;
color:#3c3c3c;
margin-bottom: 6px;
white-space: nowrap;
}
.trash_btn{
    border: none;
    background-color: #f5f5f5;
}