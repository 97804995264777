.tips-page-head {
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
}

.p-inputtext {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #3c3c3c;
}

.radio-check {
margin-left: 5px;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #818181;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #36a41d !important;
    background: #36a41d !important;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
    transform: translateZ(0) scale(0.5, 0.5) !important;
    visibility: visible;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
    border-color: #36a41d !important;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none !important;
}

.tip-submit-button {
    background-color: #36a41d !important;
    color: #ffffff !important;
    border-radius: 4px !important;
    border: none !important;
}

.count-text {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 1.5px;
    color: #818181;
}

.tip-image {
    width: 100% !important;
    height: 180px !important;
    border-radius: 4px !important;
}

.status-text {
    height: 22px;
    background-color: #DFF6DD !important;
    padding: 4px 9px 4px 9px !important;
    border-radius: 4px;
    color: #107C10;
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    text-align: left;

}
.head-htip{
font-family: "Roboto";
font-size: 12px;
font-weight: 400;
line-height: 14px;
letter-spacing: 0.25px;
color: #818181;
margin-bottom: 5px !important;
}
.health-tip-text{
font-family: "Roboto";
font-size: 14px;
font-weight: 400;
line-height: 16px;
letter-spacing: 0.5px;
color: #3c3c3c;
}

.edt-btn{
    width: 100px !important;
    height: 32px !important;
    border-radius: 4px !important;
    border: 1px solid #c2c2c2 !important;
background-color: #ffffff !important;
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16px !important;
letter-spacing: 0.5px !important;
color: #c2c2c2 !important;
}
.dlt-btn{
    width: 100px !important;
    height: 32px !important;
    border-radius: 4px !important;
    border: 1px solid #ececec !important;
background-color: #f8f8f8 !important;
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16px !important;
letter-spacing: 0.5px !important;
color: #c2c2c2 !important;
}