.p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}
.pending-table-heads .p-datatable-thead > tr > th {
    padding: 12px 8px 0px 16px !important;
    background-color: #f5f5f5 !important;
    /* color: var(--ym-text-primary) !important; */
}
.p-datatable .p-datatable-tbody > tr {
    border-bottom: 0.5px solid #e6e6e6;
    background-color: #ffffff !important;
}
.action_btn .p-button.p-button-rounded {
    border-radius: 2px !important;
    background-color: #F5F5F5 !important;
    border-color: none !important;
    outline: none !important;
    color: #3c3c3c !important;
    white-space: nowrap !important;
}
.action_btn .p-button.p-button-rounded:hover {
   color: #36a41d !important;
    background-color: #E8F6E6 !important;
   
}
.action_btn .p-button {
    color: #ffffff;
    background: green !important;
    border: none !important ;
    padding: 0.5rem 1rem;
    font-size: 12px !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.pi-eye:before {
    font-size: 12px !important;
}

.p-inputtext .p-component .p-column-filter .p-column-filter::placeholder {
color: #36a41d !important;
}
