@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap");
/* .Main {
  display: flex;
} */

.Right-side .source .content{
  /* Frame 2017 */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 16px 20px;
  gap: 10px;

  width: 468px !important;
  height: 496px;

  /* Type/white */

  background: #ffffff;
  /* Dividers-&-border / Input Border */

  /* border: 1px solid #d2d2d2; */
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.source {
  width: auto !important;
  height: 150px !important;
  left: 0px;
  top: 40px;
  padding-top: 5px;
}
.playersource{
  width: auto !important;
  height: 276px !important;
  left: 0px;
  top: 40px;
  padding-top: 5px;
}

.p-card .p-card-content {
  padding: 0;
}

.chips {
  /* Frame 2236 */

  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;

  width: 74px;
  height: 35px;

  /* Type/white */

  background: #ffffff;
  /* Type/600 */

  border: 1px solid #818181;
  border-radius: 63px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.p-chip {
  margin-right: 5px;
  background-color: transparent !important;
  border: 1px solid #818181 !important;
  color: #3c3c3c !important;
  border-radius: 63px !important;
  margin-bottom: 5px !important;
}

/* .p-component {
  padding: 5px 16px;
  margin-right: 10px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
} */

.cuisine .p-chip-text {
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.cuisine h6,
.about h6,
.Right-side h6,
.preference h6,
.popular h6,
.meal h6,
.foodtype h6,
.eaterytype h6,
.ambience h6,
.serving h6,
.values h6,
.content h6,
.serviceoptions h6 {
  /* font-family: "Rubik"; */
 color: #3c3c3c !important;
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 15px;
}

.p-chip-text {
  font-family: "Rubik" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px !important;
  /* line-height: 19px; */
  line-height: 1.5 !important;
  margin-top: 0rem !important; 
  margin-bottom: 0rem !important;
  padding: 5px !important;
}
.ym-about-input{
  word-wrap: break-word;
  font-size: 14px;
}

.span-data {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  color: #818181 !important;
}

.items-display{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px; 
}
.cuisine-display{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
.facilities-features {
  font-family: 'Rubik';
  font-weight: 400;
  font-size: 16px;
  color: #818181 !important;
}

.check-icon {
  font-size: 14px !important;
}