.content h6 {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin-bottom: 6px;
  color: #3c3c3c;
}
.yeldam-ratings-heading {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #3c3c3c;
  margin-bottom: 8px;
}
.p-progressbar {
  border: 0 none;
  height: 6px !important;
  background: #dee2e6;
  border-radius: 3px;
}

.p-progressbar .p-progressbar-label {
  display: none !important;
}
.ratinghead {
  font-family: "Roboto" !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: #818181;
}
.ratingnumber {
  font-family: "Roboto" !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: #3c3c3c;
}
.green .p-progressbar-value {
  background-color: #36a41d !important;
}

.orange .p-progressbar-value {
  background-color: #e4b086 !important;
}

.pink .p-progressbar-value {
  background-color: #faafbc !important;
}
