.main-details-profile img {
    width: 160px !important;
    height: 160px !important;
}

.main-details-profile .p-image-preview-indicator {
    width: 160px !important;
    height: 160px !important;
}

.resoninput .p-inputtext {
    border: 1px solid #ececec !important;
}

.Agentname-div {
    background-color: #F8F8F8 !important;
    border-radius: 32px !important;
    padding: 8px 16px 8px 12px;
    width: max-content;
    font-family: "Roboto" !important;
    font-size: 10px;
    font-weight: 500;
    line-height: 11.72px;
    letter-spacing: 0.4000000059604645px;
color: #818181;
}
.p-image .p-component .dietitian-profile .p-image-preview-container{
    width: 180px;
}
.dietitian-profile .p-image-preview-indicator{
    width: 180px;
}
.dietitian-profile .p-image-preview-container>img, .p-image-preview-container {
    object-fit: cover;
    width: 180px !important;
    height: 180px !important;
    border-radius: 20px;
    margin-right: 15px ;
}
.dietitian-profile img {
    width: 180px;
    height: 180px;
}

.reason-disc-box{
    width: 100% !important;
    height: auto;
    background-color: #F8F8F8;
    padding: 12px;
    border: 1px solid #ececec;
    border-radius: 4px;
    overflow: hidden;
}