@charset "utf-8";

:root {
	--font-family: 'Rubik';
	--fs-10: 0.625em;
	--fs-12: 0.750em;
	--fs-14: 0.875em;
	--fs-16: 1em;
	--fs-20: 1.25em;
	--fs-24: 1.5em;
	--fs-35: 2.188em;
	--fs-49: 3.063em;
	--fs-61: 3.813em;
	--fs-98: 6.125em;
	--fw-300: 300;
	--fw-400: 400;
	--fw-500: 500;
	--fw-600:600;
	--fw-700:700;

	/* UX Background Style Solid Color */
	--ym-bg-white: #ffffff;
	--ym-bg-primary-900: #006200;
	--ym-bg-primary-800: #238119;
	--ym-bg-primary-600: #36A41D;
	--ym-bg-primary-400: #63BF58;
	--ym-bg-primary-200: #A5D99E;
	--ym-bg-primary-50: #E8F6E6;
	--ym-bg-secondary-900: #43178A;
	--ym-bg-secondary-800: #552398;
	--ym-bg-secondary-600: #6C32A9;
	--ym-bg-secondary-400: #8856BB;
	--ym-bg-secondary-200: #9D74C7;
	--ym-bg-secondary-100: #D4C4E7;
	--ym-bg-typo-900: #1E1E1E;
	--ym-bg-typo-800: #3C3C3C;
	--ym-bg-typo-600: #818181;
	--ym-bg-typo-500: #C2C2C2;
	--ym-bg-typo-400: #DEDEDE;
	--ym-bg-typo-200: #F5F5F5;
	--ym-bg-disable: #D2D2D2;

	/* UX Text Style Solid Color */
	--ym-text-white: #ffffff;
	--ym-text-primary-stat: #44B273;
	--ym-text-primary-900: #006200;
	--ym-text-primary-800: #238119;
	--ym-text-primary-600: #36A41D;
	--ym-text-primary-400: #63BF58;
	--ym-text-primary-200: #A5D99E;
	--ym-text-primary-50: #E8F6E6;
	--ym-text-secondary-900: #43178A;
	--ym-text-secondary-800: #552398;
	--ym-text-secondary-600: #6C32A9;
	--ym-text-secondary-400: #8856BB;
	--ym-text-secondary-200: #9D74C7;
	--ym-text-secondary-100: #D4C4E7;
	--ym-text-typo-900: #1E1E1E;
	--ym-text-typo-800: #3C3C3C;
	--ym-text-typo-600: #818181;
	--ym-text-typo-500: #C2C2C2;
	--ym-text-typo-400: #DEDEDE;
	--ym-text-typo-200: #F5F5F5;
	--ym-text-disable: #D2D2D2;
	--ym-text-error:#F07676;

	/* UX Border Style Solid Color */
	--input-border: #D2D2D2;
	--divider-border: #ECECEC;
}
/* Font Size */
/* Font weights */

.fs-10 {
    font-size: var(--fs-10) !important;
}

.fs-12 {
    font-size: var(--fs-12) !important;
}

.fs-14 {
    font-size: var(--fs-14) !important;
}

.fs-16 {
    font-size: var(--fs-16) !important;
}

.fs-20 {
    font-size: var(--fs-20) !important;
}

.fs-24 {
    font-size: var(--fs-24) !important;
}

.fs-35 {
    font-size: var(--fs-35) !important;
}

.fs-49 {
    font-size: var(--fs-49) !important;
}

.fs-61 {
    font-size: var(--fs-61) !important;
}

.fs-98 {
    font-size: var(--fs-98) !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}
/*bg css*/
.ym-bg-white {
	background-color: var(--ym-bg-white) !important;
}

.ym-bg-primary-900 {
	background-color: var(--ym-bg-primary-900) !important;
}

.ym-bg-primary-800 {
	background-color: var(--ym-bg-primary-800) !important;
}

.ym-bg-primary-600 {
	background-color: var(--ym-bg-primary-600) !important;
}

.ym-bg-primary-400 {
	background-color: var(--ym-bg-primary-400) !important;
}

.ym-bg-primary-200 {
	background-color: var(--ym-bg-primary-200) !important;
}

.ym-bg-primary-50 {
	background-color: var(--ym-bg-primary-50) !important;
}

.ym-bg-secondary-900 {
	background-color: var(--ym-bg-secondary-900) !important;
}

.ym-bg-secondary-800 {
	background-color: var(--ym-bg-secondary-800) !important;
}

.ym-bg-secondary-600 {
	background-color: var(--ym-bg-secondary-600) !important;
}

.ym-bg-secondary-400 {
	background-color: var(--ym-bg-secondary-400) !important;
}

.ym-bg-secondary-200 {
	background-color: var(--ym-bg-secondary-200) !important;
}

.ym-bg-secondary-100 {
	background-color: var(--ym-bg-secondary-100) !important;
}

.ym-bg-typo-900 {
	background-color: var(--ym-bg-typo-900) !important;
}

.ym-bg-typo-800 {
	background-color: var(--ym-bg-typo-800) !important;
}

.ym-bg-typo-600 {
	background-color: var(--ym-bg-typo-600) !important;
}

.ym-bg-typo-500 {
	background-color: var(--ym-bg-typo-500) !important;
}

.ym-bg-typo-400 {
	background-color: var(--ym-bg-typo-400) !important;
}

.ym-bg-typo-200 {
	background-color: var(--ym-bg-typo-200) !important;
}
   
.ym-bg-disable {
	background-color: var(--ym-bg-disable) !important;
}

/*bg css ends*/

/*text css*/
.ym-text-white {
	color: var(--ym-text-white) !important;
}

.ym-text-primary-900 {
	color: var(--ym-text-primary-900) !important;
}

.ym-text-primary-800 {
	color: var(--ym-text-primary-800) !important;
}

.ym-text-primary-600 {
	color: var(--ym-text-primary-600) !important;
}

.ym-text-primary-400 {
	color: var(--ym-text-primary-400) !important;
}

.ym-text-primary-200 {
	color: var(--ym-text-primary-200) !important;
}

.ym-text-primary-50 {
	color: var(--ym-text-primary-50) !important;
}

.ym-text-secondary-900 {
	color: var(--ym-text-secondary-900) !important;
}

.ym-text-secondary-800 {
	color: var(--ym-text-secondary-800) !important;
}

.ym-text-secondary-600 {
	color: var(--ym-text-secondary-600) !important;
}

.ym-text-secondary-400 {
	color: var(--ym-text-secondary-400) !important;
}

.ym-text-secondary-200 {
	color: var(--ym-text-secondary-200) !important;
}

.ym-text-secondary-100 {
	color: var(--ym-text-secondary-100) !important;
}

.ym-text-typo-900 {
	color: var(--ym-text-typo-900) !important;
}

.ym-text-typo-800 {
	color: var(--ym-text-typo-800) !important;
}

.ym-text-typo-600 {
	color: var(--ym-text-typo-600) !important;
}

.ym-text-typo-500 {
	color: var(--ym-text-typo-500) !important;
}

.ym-text-typo-400 {
	color: var(--ym-text-typo-400) !important;
}

.ym-text-typo-200 {
	color: var(--ym-text-typo-200) !important;
}
   
.ym-text-disable {
	color: var(--ym-text-disable) !important;
}
.ym-text-error{
	color: var(--ym-text-error) !important;
}
/*text css ends*/

/*Border*/
.input-border{
	border:var(--input-border) !important;
}

.divider-border{
	border:var(--divider-border) !important;
}

/*buttons*/
.btn-primary{
	background-color: var(--ym-bg-primary-600) !important;
    border-color: var(--ym-bg-primary-600) !important;
	color: var(--ym-text-white) !important;
}
.btn-primary:hover,.btn-outline-primary:hover{
	background-color: var(--ym-bg-primary-800) !important;
    border-color: var(--ym-bg-primary-800) !important;
	color: var(--ym-text-white) !important;
}

.btn-check:focus+.btn-primary, .btn-primary:focus,
.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus{
	background-color: var(--ym-bg-primary-900) !important;
    border-color: var(--ym-bg-primary-900) !important;
	color: var(--ym-text-white) !important;
}

.btn,.bth:focus{
	box-shadow: none !important;
	border-radius: 0.25em;
  }

  .btn-outline-primary{
	border-color: var(--ym-bg-primary-600) !important;
	color: var(--ym-text-primary-600) !important;
  }

  .btn-outline-primary:hover{
	 border-color: var(--ym-bg-primary-800) !important;
	color: var(--ym-text-white) !important;
  }

  .disabled {
	border: 0;
	background-color: var(--input-border);
  }
  .btn-link {
	text-decoration: none;
	color:var(--ym-text-typo-600) !important;
	outline: 0;
    border: 0;
	padding: 0;
  }

  .stat-text-400{
	color: var(--ym-text-primary-stat) !important;
  }