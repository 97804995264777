.p-datatable .p-datatable-thead > tr > th input {
    padding: 8px 4px !important;
}

.add-settings-button {
    width: 180px !important;
    background-color: #36a41d !important;
    border-color: #36a41d!important;
    color: #fff!important;
    white-space: nowrap !important;
}