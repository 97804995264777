.send-consent-confirmation .p-dialog-header {
    display: none !important;
}

.send-consent-confirmation .p-dialog-content {
    padding: 24px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important; 
    border-radius: 12px !important;
}
.mail-icon-bg{
    width: 64px;
    height: 64px;
    background-color: #E8F6E6;
    border-radius: 50%;
    
}

.mail-dialog-head {
    font-family: "Rubik" !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.7px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c !important;
    margin-bottom: 8px;
    text-align: center !important;
}

.mail-dialog-caption {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #3c3c3c !important;
    margin-bottom: 32px !important;
    text-align: center !important;
}

.cancel_send_btn {
    background-color: #ffffff !important;
    border: 1px solid #36a41d !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.41px !important;
    letter-spacing: 0.5px !important;
    color: #36a41d !important;
}
.send_btn{
    background-color: #36a41d !important;
    border: 1px solid #36a41d !important;
    border-radius: 4px !important;
    padding: 8px 16px !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.41px !important;
    letter-spacing: 0.5px !important;
    color: #ffffff !important;
}
.pi-envelope:before {
    font-size: 12px !important;
}