.store-pic-size img {
    width: 120px;
    height: 120px;
}

.store-pic-size .p-image-preview-indicator {
    width: 120px;
    height: 120px;

}

.store-pic-size .p-image-preview-container>img,
.p-image-preview-container {
    object-fit: cover;
    width: 120px !important;
    height: 120px !important;
    border-radius: 20px;
}

.p-image .p-component .store-pic-size .p-image-preview-container {
    width: 120px;
}

.picheads-store {
    font-family: "Roboto";
    font-size: 12px;
    font-weight: 600;
    line-height: 14.06px;
    letter-spacing: 0.25px;
    color: #3c3c3c;
}