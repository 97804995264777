.add-healthtip-button {
    background-color: #36a41d !important;
    border-radius: 4px !important;
    white-space: nowrap !important;
    border: 1px solid #36a41d !important;
}

.action-btn-icon {
    background-color: #f5f5f5 !important;
    border-radius: 4px !important;
    border: none !important;
    color: #3c3c3c !important;
}

.tiptable {
    border: none !important;
}

.trash-icon {
    width: 21px !important;
    color: #D83B01 !important;
    height: 24px !important;
    padding: 20px !important;
    border: 1px solid #EAECF0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 4px !important;
}

.delete-dialog .p-dialog-header {
    border-bottom: 0 none;
    background: #ffffff;
    color: #495057;
    padding: 0rem !important;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}
.login-model .p-dialog-header {
    border-bottom: 1px solid #f5f5f5 !important;
    padding: 1rem !important;
}
.tip-pic{
    width: 70px !important;
    height: 66px !important;
}

.dlt-txt {
    font-family: "Rubik";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    text-align: center;
    color: #3c3c3c;
}

.no-btn {
    width: 100px !important;
    height: 32px !important;
    border: 1px solid #36a41d !important;
    background-color: #ffffff !important;
    color: #36a41d !important;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center !important;

}
.yes-btn {
    width: 100px !important;
    height: 32px !important;
    border: 1px solid #36a41d !important;
    background-color: #36a41d !important;
    color: #ffffff !important;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: center !important;

}
.pi-pencil:before {
    font-size: 12px !important;
}
.pi-trash:before {
font-size: 12px !important;
}
.p-dialog-draggable .p-dialog-header {
    cursor: context-menu !important;
}
.p-dialog-mask {
    position: fixed !important;
}
