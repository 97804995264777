.btn_submit {
    width: 80px !important;
    height: 32px !important;
    border-radius: 4px !important;
    background-color: #36a41d !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.5px !important;
    color: #FFFFFF !important;
}

.analytics-people-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #F0FDF6;
    border: 2px solid #F0FDF6;

}

.analytics-people-div:hover {
    border: 2px solid #9BDFC4;
    cursor: pointer;
}

.analytics-visitors-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #F9F4FF;
    border: 2px solid #F9F4FF;

}

.analytics-visitors-div:hover {
    border: 2px solid #B1ABF8;
    cursor: pointer;
}

.analytics-visitors-div:active {
    border: 2px solid #B1ABF8 !important;
    cursor: pointer;
}

.analytics-visits-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #FFF6EF;
    border: 2px solid #FFF6EF;
}

.analytics-visits-div:hover {
    border: 2px solid #E4B086 !important;
    cursor: pointer;
}

.analytics-time-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #F2FCFF;
    border: 2px solid #F2FCFF;

}

.analytics-time-div:hover {
    border: 2px solid #62B2FD;
    cursor: pointer;
}

.analytics-signs-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #F4F6FA !important;
    border: 2px solid #F4F6FA;
}

.analytics-signs-div:hover {
    border: 2px solid #6C84B5;
    cursor: pointer;
}

.analytics-response-div {
    width: 160px !important;
    height: 80px !important;
    border-radius: 4px;
    background-color: #FAFAFA;
    border: 2px solid #FAFAFA;
}

.analytics-response-div:hover {
    border: 2px solid #C2C2C2;
    cursor: pointer;
}

.people-count {
    font-family: "Rubik" !important;
    font-size: 35px;
    font-weight: 400;
    line-height: 41px;
    letter-spacing: 0.25px;
    color: #1e1e1e;
    margin-bottom: 8px;
}

.people-text {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #1e1e1e;
    margin-bottom: 0px !important;
}

.percentage {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #137246;
    margin-bottom: 0px !important;
}

.three-heads {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
    margin-bottom: 8px;
}

.top-three-divs {
    width: 95% !important;
    height: 265px !important;
    background-color: #fafafa;
    border-radius: 4px;
}

.p-progressbar {
    border: 0 none;
    height: 6px !important;
    background: #dee2e6;
    border-radius: 3px;
}

.p-progressbar .p-progressbar-label {
    display: none !important;
}

.chrome-div .p-progressbar .p-progressbar-value {
    background: #9BDFC4 !important;
}

.firefox-div .p-progressbar .p-progressbar-value {
    background: #FAAFBC !important;
}

.edge-div .p-progressbar .p-progressbar-value {
    background: #62B2FD !important;
}

.safari-div .p-progressbar .p-progressbar-value {
    background: #B1ABF8 !important;
}

.others-div .p-progressbar .p-progressbar-value {
    background: #6C84B5 !important;
}

.browsehead {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
}

.browsepercentage {
    font-family: "Roboto" !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #3c3c3c;
}

.country-visitors {
    width: 100% !important;
    height: 265px !important;
    background-color: #fafafa;
    border-radius: 4px;
}

.top-pages {
    width: 100% !important;
    height: 265px !important;
    background-color: #fafafa;
    border-radius: 4px;
}

.country-table {
    background-color: #ffffff;
    width: 100%;
    border-radius: 4px !important;
}

.Country-visitors {
    font-family: "Rubik" !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.10000000149011612px;
    color: #3c3c3c;
    margin-bottom: 0px;
}

.Country-number {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
    margin-bottom: 0px;

}

.most-searched-words {
    width: 100% !important;
    background-color: #fafafa;
    border-radius: 4px;
    height: 265px;
}

.word-div {
    background-color: #ffffff;
    border-radius: 4px;
}

.word-count-text {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
    margin-bottom: 0px;
}

.word-count-div {
    padding: 0px 16px 0px 16px;
}

.word-count {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3C3C3C;
    margin-bottom: 0px;
}