.p-tabview .p-tabview-panels {
    padding: 0px !important;
}

.p-tabview-ink-bar {
    display: none !important;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: #36a41d !important;
}

.p-inputtext:enabled:hover {
    /* border-color: #36a41d !important; */
}

.p-inputtext::placeholder {
    font-size: 12px !important;
    font-family: "Roboto" !important;
    opacity: 50% !important;
    color: #818181 !important;
}

.email-entry .p-inputtext {
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    letter-spacing: 0.5px !important;
    color: #1e1e1e !important;
    height: 44px !important;
}

.edit-info-heading {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 23.7px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c !important;
}
.edit-info-heading:hover {
cursor: pointer;
}

.edit-details-head {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #818181;
}

.head-contact {
    font-family: "Rubik";
    font-size: 20px;
    font-weight: 600;
    line-height: 23.7px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
}

.getData_btn {
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16.41px !important;
    letter-spacing: 0.5px !important;
    color: #36a41d !important;
    border: 1px solid #36a41d !important;
    background-color: #ffffff !important;
    padding: 12px 24px !important;
    white-space: nowrap !important;
}

.getData_btn:hover {
    background-color: #E8F6E6 !important;
    cursor: pointer !important;
}

.next {
    background-color: #36a41d !important;
    color: #fff !important;
    border-radius: 5px !important;
    border-color: #36A41d !important;
    font-family: "Rubik" !important;
    font-weight: 400 !important;
    border: 2px solid #36A41D !important;
    height: 44px !important;
    width: 120px !important;
    display: flex !important;
    justify-content: center !important;

}

.ym-add-button-previous {
    background-color: #ffffff !important;
    color: #36A41D !important;
    border-radius: 5px !important;
    border-color: #36A41d !important;
    font-family: "Rubik" !important;
    font-weight: 400 !important;
    border: 1px solid #36A41D !important;
    height: 44px !important;
    width: 120px !important;
    display: flex !important;
    justify-content: center !important;
}

.p-button-success {
    background-color: #36a41d !important;
    color: #fff !important;
    border-radius: 5px !important;
    border-color: #36A41d !important;
    font-family: "Rubik" !important;
    font-family: 15px !important;
    font-weight: 400 !important;
    border: 2px solid #36A41D !important;
    height: 44px !important;
    width: 120px !important;
    display: flex !important;
    justify-content: center !important;
}

.card-footer{
    border-top: 1px solid #ececec;
    height: 93px;
}

.pref-label {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
}

.pref-label-selected {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #36A41D !important;
}

.Sunday-timing {
    background-color: #F5F5F5;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    width: 295px
}
.p-checkbox .p-checkbox-box.p-highlight {
    border-color: #36A41D !important;
    background: #36A41D !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #ffffff !important;
}
.p-checkbox .p-checkbox-box.p-focused .p-checkbox-icon {
    color: #36A41D !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #36A41D !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    box-shadow: none !important;
}
.add-button {
    background-color: #818181;
    padding: 12px, 58px, 12px, 58px;
    color: #ffffff;
    font-size: 12px;
    border: none;
    width: 100px;
    height: 40px;
    border-radius: 5px;
}
.p-tabview-title {
    font-family: "Rubik" !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.59px !important;
    letter-spacing: 0.10000000149011612px !important;
    
}
.button_add_education {
    width: 117px !important;
    height: 32px !important;
    padding: 8px !important;
    background-color: #fafafa !important;
    border: none !important;
    font-family: "Roboto" !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 14.06px !important;
    letter-spacing: 0.25px !important;
    color: #3c3c3c !important;
}

.p-dropdown:not(.p-disabled):hover {
    border-color: #D2D2D2 !important;
}
.check-wrapper {
    accent-color: #36a41d !important;
    width: 14px !important;
}
.language-caption {
    font-family: "Roboto";
    font-size: 10px;
    font-weight: 400;
    line-height: 11.72px;
    letter-spacing: 0.25px;
    color: #818181;
}
.button-container {
    display: flex;
    justify-content: space-between;
    align-items: end;
    padding: 1rem;
    background-color: #FAFAFA;
    position: sticky;
    bottom: 0;
}
.p-dropdown .p-dropdown-label {
    display: flex !important;
    align-items: center !important;
}