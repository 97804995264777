.status-giving{
font-family: "Rubik";
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0.15000000596046448px;
text-align: center;
color: #3c3c3c;
}
.canceling_btn{
    border: 1px solid #36a41d !important;
    color: #36a41d !important;
    border-radius: 4px !important;
    background-color: #ffffff !important;
}
.approve-reject-dialog .p-dialog .p-dialog-header {
    display: none !important;
}