.ym-stat-card{
    width: 160px;
    height: 80px;
}
.people-site{
    background-color: #F0FDF6 !important;
    border-radius: 4px !important;
    border: none;
}
.visitors-card{
    background-color: #F9F4FF !important;
    border-radius: 4px !important;
    border: none;
}
.visits-card{
    background-color: #FFF6EF !important;
    border-radius: 4px !important;
    border: none;
}
.avg-on-site{
    background-color: #F2FCFF !important;
    border-radius: 4px !important;
    border: none;
}
.new-signup{
    background-color: #F4F6FA !important;
    border-radius: 4px !important;
    border: none;
}
.response-time{
    background-color: #FAFAFA !important;
    border-radius: 4px !important;
    border: none;
}