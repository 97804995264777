.p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}
.btn_cancel{
    width: 100px !important;
    border: 1px solid #36a41d !important;
    color: #36a41d !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    background-color: #ffffff !important;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}