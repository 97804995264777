@charset "utf-8";


html {
    scroll-behavior: smooth;
}

body {
    font-family: var(--pop-font-family) !important;
    background-color: var(--ym-bg-light) !important;
    font-size: var(--fs-14);
    /* color: var(--ym-text-dark-theme); */
}

p {
    margin-bottom: 0;
}

header {
    background-color: var(--ym-bg-white);
    box-shadow: var(--ym-shadow-sm);
    position: sticky;
    top: 0;
    z-index: 1;
}

.p-chart {
    display: flex !important;
    justify-content: center !important;
}

.dashboard-heading {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
    margin-bottom: 0px;
    margin-top: 0px;

}

.calen .p-button {
    color: #3c3c3c !important;
    background: #ffffff !important;
    border-top: 1px solid #ececec !important;
    border-bottom: 1px solid #ececec !important;
    border-right: 1px solid #ececec !important;
    border-left: none !important;
    padding: 0rem !important;
    font-size: 0.5rem !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.p-inputtext {
    font-family: "Roboto" !important;
    font-size: 12px !important;
    color: #495057;
    background: #ffffff;
    padding: 0.5rem 0.5rem;
    /* border-top: 1px solid #ececec !important;
    border-bottom: 1px solid #ececec !important;
    border-left: 1px solid #ececec !important;
    border-right: none !important; */
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    appearance: none;
    border-radius: 3px;
}

.week-head {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c !important;

}

.total-rests {
    width: 230px !important;
    height: auto;
    border: 1px solid #ececec !important;
    background-color: #ffffff;
    border-radius: 4px;

}

.pending-rests {
    width: 160px !important;
    height: auto;
    border: 1px solid #ececec !important;
    background-color: #ffffff;
    border-radius: 4px;
}

.total-rest-image-div {
    width: 56px;
    height: 56px;
    background-color: #F0FDF6;
    border-radius: 4px;
}

.total-diet-image-div {
    width: 56px;
    height: 56px;
    background-color: #E8F6E6 !important;
    border-radius: 4px;
}

.sidediv {
    background-color: #FAFAFA !important;
    border-radius: 4px;
}

.app-fe-text {
    color: #818181;
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
}

.app-food-events {
    border-top: 1px solid #ececec !important;
    width: 100%;
    background-color: #ffffff;
    border-bottom: 1px solid #ececec !important;
}

.chart-head {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
}

.chart-body {
    background-color: #fafafa;
    border-radius: 4px;
    height: 220px !important;
}

.text-chart-opt {
    font-family: "Roboto" !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.25px;
    color: #3c3c3c;
}

.chart-top-bodies {
    background-color: #fafafa;
    border-radius: 4px;
    width: 95% !important;
    height: 230px !important;
}

.ev-count {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
}

.total-store-image-div {
    width: 56px;
    height: 56px;
    background-color: #FFF6EF;
    border-radius: 4px;
}

.total-events-image-div {
    width: 56px;
    height: 56px;
    background-color: #F4F6FA;
    border-radius: 4px;
}

.total-agents-image-div {
    width: 56px;
    height: 56px;
    background-color: #F9F4FF;
    border-radius: 4px;
}

.pic-pic {
    width: 32px;
    height: 32px;
}

.weekgraph {
    width: 48.5% !important;
}

.count-heading {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 5px;
    color: #818181;
    /* white-space: nowrap; */
}

.count-value {
    font-family: "Rubik" !important;
    font-size: 22px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
    margin-bottom: 20px;
}

.pending-count-div {
    width: 24px;
    height: 24px;
    background-color: #FFF4CE;
    border-radius: 4px;
}

.newagents-div {
    width: 24px;
    height: 24px;
    background-color: #F5F5F5;
    border-radius: 4px;
}

.pending-count {
    color: #3c3c3c !important;
    font-family: "Roboto" !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.4000000059604645px;

}

.pend-text {
    font-family: "Roboto" !important;
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0.4000000059604645px;
    color: #818181;
    white-space: nowrap;
}

.dropdown-toggle::after {
    display: none;
}

.form-control:focus {
    box-shadow: none !important;
}

.form-select:focus {
    box-shadow: none !important;
}

.nav-tabs {
    border-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border: 0;
    border-bottom: 2px solid var(--ym-text-primary-default);
    font-weight: var(--fw-400);
    font-size: var(--fs-17);
    color: var(--ym-text-primary-alt);
}

.nav-tabs .nav-link {
    margin-bottom: -2px;
    border: 0;
    border-bottom: 2px solid transparent;
    color: var(--ym-text-secondary);
}

.badge {
    border-radius: 2px;
    padding: 2px 6px;
    font-size: var(--fs-16);
    font-weight: var(--fw-600);
}


.badge-info {
    background-color: var(--divider-default);
    color: var(--ym-text-secondary);
    line-height: 1.2;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 8px;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: #ced4da !important;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.p-inputtext:enabled:hover {
    border-color: #ced4da !important;
}

.active .badge-info {
    background-color: #b1dfff;
    color: var(--ym-text-primary-default);
    line-height: 1.2;
    border-radius: 5px;
    font-size: 14px;
    padding: 2px 8px;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border: none;
    border-bottom: 2px solid var(--ym-text-primary-default);
}

*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: rgb(157 156 156 / 11%);
}

*::-webkit-scrollbar-thumb {
    background: #32323212;
    ;
}

*::-webkit-scrollbar-thumb:hover {
    background: #a1a1a199;
}

*::-moz-scrollbar {
    width: 6px;
}

*::-moz-scrollbar-track {
    background: rgba(123, 123, 123, 0.22);
}

*::-moz-scrollbar-thumb {
    background: #a7a7a7;
}

*::-moz-scrollbar-thumb:hover {
    background: #5a5a5a;
}

/* Font weights */
.fs-5 {
    font-size: var(--fs-5) !important;
}

.fs-10 {
    font-size: var(--fs-10) !important;
}

.fs-12 {
    font-size: var(--fs-12) !important;
}

.fs-14 {
    font-size: var(--fs-14) !important;
}

.fs-16 {
    font-size: var(--fs-16) !important;
}

.fs-18 {
    font-size: var(--fs-18) !important;
}

.fs-20 {
    font-size: var(--fs-20) !important;
}

.fs-24 {
    font-size: var(--fs-24) !important;
}

.fs-28 {
    font-size: var(--fs-28) !important;
}

.fs-32 {
    font-size: 32px !important;
}

.fs-42 {
    font-size: var(--fs-42) !important;
}

.fs-68 {
    font-size: var(--fs-68) !important;
}

.fs-96 {
    font-size: var(--fs-96) !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

/* TEXT SOLID COLORES STYLES */
.ym-text-white-color {
    color: var(--ym-text-white-color) !important;
}

.ym-text-primary {
    color: var(--ym-text-primary) !important;
}

.ym-text-secondary {
    color: var(--ym-text-secondary) !important;
}

.ym-text-disabled {
    color: var(--ym-text-disabled) !important;
}

.ym-text-error {
    color: var(--ym-text-error) !important;
}

.ym-text-warning {
    color: var(--ym-text-warning) !important;
}

.ym-text-success {
    color: var(--ym-text-success) !important;
}

.ym-text-success-alt {
    color: var(--ym-text-success-alt) !important;
}

.ym-text-primary-alt {
    color: var(--ym-text-primary-alt) !important;
}

/*.ym-text-link-primary {
    color: var(--ym-text-link-primary) !important;
}*/

.ym-text-404 {
    color: var(--ym-text-404) !important;
}

.align-items-center {
    align-items: center;
}

/* BACKGROUND COLORES STYLES */
.btn-close:focus {
    box-shadow: none !important;
}

.ym-modal-header .btn-close {
    position: relative;
    top: -30px;
    right: -10px;
    z-index: 1;
}

.ym-modal-header>.login-close {
    top: 15px !important;
    right: 13px !important;
}

.ym-bg-primary {
    background-color: var(--ym-bg-primary);
}

.ym-bg-dark {
    background-color: var(--ym-bg-dark);
}

.ym-bg-darker {
    background-color: var(--ym-bg-darker);
}

.ym-bg-dark-alt {
    background-color: var(--ym-bg-dark-alt);
}

.ym-bg-secondary {
    background-color: var(--ym-bg-secondary);
}

.ym-bg-light {
    background-color: var(--ym-bg-light);
}

.ym-bg-lighter {
    background-color: var(--ym-bg-lighter);
}

.ym-bg-lighter-alt {
    background-color: var(--ym-bg-lighter-alt);
}

.ym-bg-white {
    background-color: var(--ym-bg-white);
}

.ym-bg-gray-10 {
    background-color: var(--ym-bg-gray-10);
}

.ym-bg-gray-150 {
    background-color: var(--ym-bg-gray-150);
}

.ym-hover-gradient-bg {
    background: var(--ym-hover-gradient-bg) !important;
}

/* SNACK LIGHT BACKGROUND COLORES STYLES */
.ym-snack-bg-error {
    background-color: var(--ym-snack-bg-error);
}

.ym-snack-bg-warning {
    background-color: var(--ym-snack-bg-warning);
}

.ym-snack-bg-success {
    background-color: var(--ym-snack-bg-success);
}

.ym-snack-bg-success-fill {
    background-color: var(--ym-snack-bg-success-fill);
}

/* ALERT SOLID BACKGROUND COLORS STYLES */

.ym-alert-bg-error {
    background-color: var(--ym-alert-bg-error);
}

.ym-alert-bg-warning {
    background-color: var(--ym-alert-bg-warning);
}

.ym-alert-bg-success {
    background-color: var(--ym-alert-bg-success);
}

.ym-alert-bg-warning-fill {
    background-color: var(--ym-alert-bg-warning-fill);
}

/* BOX SHADOW COLOR */
.ym-shadow-xs {
    box-shadow: var(--ym-shadow-xs) !important;
}

.ym-shadow-sm {
    box-shadow: var(--ym-shadow-sm) !important;
}

.ym-shadow-md {
    box-shadow: var(--ym-shadow-md) !important;
}

.ym-shadow-lg {
    box-shadow: var(--ym-shadow-lg) !important;
}

.ym-shadow-xl {
    box-shadow: var(--ym-shadow-xl) !important;
}

.ym-action-btn {
    padding: 6px 8px 5px 8px;
    font-size: var(--fs-16);
    text-align: center;
    margin: 0 2px;
}

.ym-action-btn:hover {
    padding: 6px 8px 5px 8px;
    border-radius: 4px;
    background-color: var(--ym-bg-light);
    color: var(--ym-text-link-primary) !important;
    cursor: pointer;
}

.ym-action-btn.text-black:hover {
    color: var(--ym-text-link-primary) !important;
}

.ym-action-btn>i {
    margin-right: 2px;
    margin-left: 2px;
}

.ym-del:hover {
    background-color: var(--ym-status-error);
}

.ym-dropdown-menu>a {
    background-color: transparent;
    border: 0;
    color: var(--ym-text-primary);
    padding: 0 10px;
}

.ym-dropdown-menu>a::after {
    display: none;
}

.ym-dropdown-menu .dropdown-menu>li>a {
    border-bottom: 1px solid var(--divider-default);
    padding: 7px 15px;
}

.ym-dropdown-menu .dropdown-menu>li>a:hover,
.ym-dropdown-menu .dropdown-menu>li>a:active {
    background-color: var(--ym-bg-lighter-alt);
    color: #1e2125;
}

.ym-dropdown-menu .dropdown-menu.show {
    top: 65px;
    border: 0;
    right: -3px !important;
}

.ym-dropdown-menu>a>i {
    font-size: var(--fs-20);
}

.invite-friends {
    width: 185px;
}

.ym-dropdown-menu>a:hover {
    background-color: transparent;
    color: var(--ym-text-primary-alt);
}

.ym-dropdown-menu>a:focus,
.ym-dropdown-menu>a:active {
    background-color: transparent;
    color: var(--ym-text-primary-alt);
    box-shadow: none !important;
}

.ym-dropdown-menu>a>span {
    display: block;
    font-size: var(--fs-10);
    font-weight: var(--fw-400);
}

.ym-dropdown-list {
    border: 1px solid var(--divider-default);
    padding: 5px;
}

.ym-notify-dropdown {
    width: 350px;
    height: 510px;
    left: -200px !important;
}

.scrolls-area {
    width: 100%;
    height: 430px;
    overflow-y: auto;
}

.ym-notify-dropdown>div>div {
    border-bottom: 1px solid var(--divider-default);
}

.ym-notify-dropdown>div>div:last-child {
    border-bottom: 0;
}

.ym-notify-height {
    height: 344px;
}

.ym-profile-dropdown>ul {
    width: 240px;
}

.ym-profile-dropdown .dropdown-menu>li>a {
    border-top: 1px solid var(--divider-default) !important;
    border-bottom: 0 !important;
}

.ym-profile-dropdown .dropdown-menu>li>button {
    border-top: 1px solid var(--divider-default) !important;
    padding: 7px 15px;
}

.align-center {
    display: flex;
    align-items: center;
}

.company-name {
    width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 5px;
}

.ym-star-rate {
    line-height: 1;
}

/* STATUS STYLES */
.ym-status {
    display: inline-block;
    padding: 0.4em 1em;
    margin: 0.4em 0;
    font-size: var(--fs-12) !important;
    font-weight: var(--fw-600);
}

.ym-status-primary {
    background-color: var(--ym-status-primary);
    color: var(--ym-status-text-primary);
}

.ym-status-warning {
    background-color: var(--ym-status-warning);
    color: var(--ym-status-text-warning);
}

.ym-status-success {
    background-color: var(--ym-status-success);
    color: var(--ym-status-text-success);
}

.ym-status-error {
    background-color: var(--ym-status-error);
    color: var(--ym-status-text-error);
}

.ym-status-default {
    background-color: var(--ym-status-default);
    color: var(--ym-status-text-default);
}

.sticky {
    position: sticky;
    z-index: 10;
    top: 0;
}

hr {
    border-color: var(--border-default);
}

/* FONT ICONS STYLES */

.pi {
    margin-right: 0px;
}

/* DRAG AND DROP */
.border-default {
    border: 1px solid var(--border-default) !important;
}

.drag-drop-box {
    border: 2px dashed var(--border-default);
    padding: 15px;
    min-height: 104px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.drag-drop-box:hover {
    background-color: var(--ym-bg-lighter-alt);
    border: 2px dashed var(--input-border-primary-alt);
    cursor: pointer;
}

.drag-drop-box>div {
    margin: auto;
}

/* MODAL POPUP */
/*.ym-modal-dialog-md {
    min-width: 600px !important;
}*/

.ym-modal-content {
    padding: 28px;
}

.ym-modal-body,
.ym-modal-header,
.ym-modal-footer {
    padding: 0 !important;
}

.ym-modal-header {
    border-bottom: 0 !important;
    text-align: center;
}

.avatar {
    width: 32px;
    height: 32px;
}

.ym-modal-footer {
    border-top: 0 !important;
}

/* NAV TABS STYLES */
.ym-nav-pills {
    margin-bottom: 15px;
}

.ym-nav-pills ul {
    border-bottom: 3px solid var(--divider-default);
}

.ym-nav-pills ul li .nav-link {
    color: var(--ym-text-disabled);
    padding: 15px 15px 10px !important;
    font-weight: 400;
}

.ym-nav-pills ul li .nav-link.active {
    position: relative;
    background-color: var(--ym-bg-white);
    border-radius: 0;
    color: var(--ym-text-primary-alt);
    font-weight: 700;
    padding: 15px 15px 10px !important;
    border-bottom: 3px solid var(--input-border-primary-alt);
    margin-bottom: -3px;
}

/* CARDS */
.ym-card {
    padding: var(--card-padding);
    box-shadow: -3px 7px 15px 0px rgb(0 0 0 / 9%) !important;
}

.ym-chart-card {
    border: 1px solid var(--divider-default) !important;
    box-shadow: 0px 0px 7px 3px rgb(0 0 0 / 9%) !important;
    transition: transform 0.3s, box-shadow 0.3s;
}

.ym-box-card:hover {
    z-index: 1;
    transform: scale(1.02);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.ym-filter-icon {
    position: absolute;
    padding: 11px 10px;
    color: var(--ym-text-disabled);
}

.ym-filter-box {
    padding: 3px 3px 3px 30px;
}

/* GRID TABLES STYLES */
.p-datatable .p-datatable-thead>tr>th {
    padding: 3px 10px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    padding: 10px 15px !important;
}

.p-datatable-scrollable-header-table .p-datatable-thead th input {
    padding-left: 30px;
    border-radius: 4px;
    transition: all 0.5s ease-in-out;
    padding-right: 10px;
    max-width: 100%;
    border: 1px solid var(--border-default);
    height: 30px;
    width: 100%;
}

.p-datatable .p-datatable-thead>tr>th,
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
    background-color: var(--ym-bg-white) !important;
    border: 0px solid var(--ym-bg-white) !important;
}

.p-datatable .p-sortable-column:not(.p-highlight):hover {
    /* background-color: var(--ym-bg-white) !important; */
}

.p-datatable .p-datatable-thead>tr>th {
    padding: 8px 4px !important;
    padding-right: 5px !important;
    /* color: var(--ym-text-primary) !important; */
}

.p-datatable .p-datatable-tbody .p-datatable .p-sortable-column:focus {
    box-shadow: none !important;
}

/* .p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 30px !important;
} */

.p-datatable .p-datatable-tbody>tr>td {
    text-align: left !important;
    padding: 12px 8px 12px 16px !important;
}

.p-datatable .p-datatable-tbody>.p-datatable-emptymessage>td {
    text-align: center !important;
    padding-top: 10% !important;
    padding-bottom: 10% !important;

}

.p-paginator {
    padding: 1rem !important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #E8F6E6 !important;
    border-color: #E8F6E6 !important;
    font-family: "Roboto" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.06px !important;
    letter-spacing: 0.25px !important;
    color: #36a41d !important;
}

.p-datatable .p-datatable-tbody>tr {
    border-bottom: 0.5px solid #e6e6e6;
}

.p-datatable-scrollable-body {
    margin-top: 10px;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    margin-top: -1px;
}

/* .pi-sort-alt:before,
.pi-sort-amount-down:before {
    content: '\f282' !important;
    font-family: bootstrap-icons !important;
} */

/* .pi-sort-amount-up-alt:before {
    content: '\f286' !important;
    font-family: bootstrap-icons !important;
} */

.p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

/* .pi-sort-alt:before,
.pi-sort-amount-down:before {
    content: '\f282' !important;
    font-family: bootstrap-icons !important;
} */

/* .pi-sort-amount-up-alt:before {
    content: '\f286' !important;
    font-family: bootstrap-icons !important;
} */

.p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

.p-datatable-scrollable-body {
    overflow-x: hidden !important;
}

.p-datatable .p-datatable-tbody>tr {
    color: var(--ym-text-primary) !important;
}

.p-datatable .p-datatable-tbody .pi-check2 {
    font-size: 22px !important;
}

.g5_filter[aria-expanded='true'] .pi-chevron-down::before {
    content: '\f286';
}

.g5_filter[aria-expanded='true'] {
    background-color: var(--ym-bg-lighter-alt) !important;
}

.g5_filter[aria-expanded='false'] {
    background-color: var(--ym-bg-white) !important;
    border: 1px solid var(--divider-default);
}

/* SLIDER SWITCH STYLES */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 25px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    height: 24px;
    width: 50px;
    cursor: pointer;
}

.slider:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    top: 2px;
}

input:checked+.slider {
    background-color: var(--ym-bg-success);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.slider-name {
    left: 53px;
    position: relative;
    top: 3px;
}

.review-textarea {
    min-height: var(--review-textarea) !important;
}

/* #endregion */

/* PROFILE STYLES */
.ym-profile-banner {
    background: var(--ym-gradient-bg-blue);
    min-height: var(--profile-img-height);
}

.ym-profile-banner-hover {
    background: var(--ym-hover-gradient-bg);
}

.ym-profile-details {
    background: var(--ym-bg-white);
    min-height: var(--profile-details-height);
    padding: 20px;
}

.ym-profile-pic {
    border-radius: 50%;
    margin-top: -50px;
    width: 128px;
    height: 128px;
    border: 3px solid var(--ym-bg-white);
    border-radius: 50%;
    background: var(--ym-bg-light-blue);
    display: flex;
    align-items: center;
    text-align: center;
    position: relative;
    z-index: 1;
}

.ym-profile-pic>i {
    font-size: var(--fs-68);
    align-self: center;
    margin: 0 auto;
    color: var(--ym-text-white-color);
}

.ym-profile-pic>img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.ym-profile-pic-hover {
    background: var(--ym-hover-gradient-bg);
}

.ym-profile-edit-pic>i {
    position: absolute !important;
    left: 21% !important;
    top: 8px !important;
    color: #b3a5a5;
}

.ym-profile-edit-pic>button {
    position: absolute !important;
    left: 32.2% !important;
    top: 37px !important;
}

/* TABS STYLES */
.tab-min-height {
    min-height: var(--tab-min-height);
}

.no-records-area {
    display: inline-grid;
    align-items: center;
    width: 100%;
    align-content: center;
    min-height: var(--tab-min-height);
}

.no-records-area>img {
    margin: auto;
    width: 139px;
}

.post-textarea {
    min-height: 64px;
}

.ym-tab-pane {
    padding: 15px 0;
}

.review-li {
    border-top: 1px solid var(--divider-default);
    border-bottom: 1px solid var(--divider-default);
    padding: 20px 0;
}

.post-li {
    border-top: 1px solid var(--divider-default);
    border-bottom: 1px solid var(--divider-default);
    padding: 20px 0;
}

/* TOOLTIP STYLES */
.ym-tooltip {
    position: relative;
    display: inline-block;
}

.ym-tooltip .ym-tooltiptext {
    visibility: hidden;
    min-width: 21em;
    background-color: var(--ym-bg-white);
    text-align: left;
    padding: 4px 8px;
    border-radius: 4px;
    position: absolute;
    z-index: 1;
    font-style: normal !important;
    border: 1px solid var(--divider-default);
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    right: 24px;
}

.ym-tooltip:hover .ym-tooltiptext {
    visibility: visible;
    cursor: pointer;
}

.bottom-tip {
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.top-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.left-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

.right-tip {
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
}

/* Swalo popups */
.swal2-styled.swal2-confirm {
    background-color: var(--ym-bg-primary) !important;
}

.swal2-popup .swal2-styled.swal2-confirm:hover {
    opacity: 0.8 !important;
}

.p-datatable .p-datatable-tbody>tr.g5-selected-row {
    background-color: #effafcff !important;
}

.left-notifiy-col {
    max-height: 520px;
    overflow-y: auto;
    overflow-x: hidden;
}

.filter-checked {
    border-left: 4px solid var(--ym-bg-primary) !important;
    border-right: none !important;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

.filter-checked:first-child {
    border-top: none !important;
}

.login-buttons {
    margin-top: 10px;
}

.g5-filter-separator {
    position: relative;
}

.g5-filter-separator:before {
    position: absolute;
    content: '';
    width: 1px;
    height: 80%;
    background: var(--divider-default);
    top: 10%;
    left: 0;
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
    color: var(--ym-text-primary) !important;
    font-weight: Var(--fw-700);
}

.breadcrumb li>a {
    text-decoration: none;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '\f285' !important;
    font-family: bootstrap-icons !important;
}

.ym-pos-sticky {
    position: sticky !important;
    top: 0;
    z-index: 1;
}

/* Filter Dropdowns */
.ym-filter-drops>.p-dropdown {
    width: 100%;
    font-family: var(--pop-font-family) !important;
}

.p-dropdown:not(.p-disabled):focus {
    box-shadow: none !important;
}

.ym-filter-drops>.p-dropdown .p-dropdown-label.p-placeholder {
    padding: 4.5px 10px;
}

.ym-filter-drops>div>div>.p-dropdown-trigger-icon {
    position: relative;
    right: 0;
}

.ym-filter-drops>.p-dropdown .p-dropdown-label {
    padding: 4.5px 10px;
}

.ym-filter-drops .p-dropdown-trigger-icon {
    font-family: 'primeicons' !important;
    font-size: var(--fs-13);
    right: 0.8rem;
    position: relative;
    padding: 1px 8px;
}

.ym-filter-drops .p-dropdown .p-dropdown-clear-icon {
    right: 0.8rem !important;
    font-size: 13px;
    top: 16px;
    padding: 1px 8px;
    background-color: var(--ym-bg-white) !important;
    z-index: 1;
}

.p-multiselect-trigger-icon {
    font-size: 12px;
}

/* .p-component,
.p-inputtext {
    font-family: var(--pop-font-family) !important;
    font-size: 14px !important;
    font-size: 14px !important;
} */
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
    padding-left: 5px !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    background-color: var(--ym-bg-lighter-alt);
    box-shadow: none !important;
}

.p-multiselect {
    border: 1px solid #ccc;
    width: 100%;
}

.p-multiselect .p-multiselect-label {
    padding: 0.3rem 0.5rem;
}

.p-multiselect-filter-container>input {
    padding: 5px !important;
    padding-left: 5px !important;
}

.p-multiselect-filter-container>input:focus {
    box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-header {
    padding-right: 0.4rem;
}

.ql-container,
.ql-editor {
    font-size: var(--fs-16) !important;
    font-family: var(--pop-font-family) !important;
}

/* .p-chips .p-chips-multiple-container {
    padding: 0 !important;
} */
/* .p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 0px !important;
} */
.p-chips .p-chip s-multiple-container .p-chips-input-token input {
    font-family: var(--pop-font-family) !important;
    font-size: var(--fs-14) !important;
}

.p-chips .p-chips-multiple-container .p-chips-input-token input:focus {
    box-shadow: none;
}

/* BUTTONS */
.btn:not(:disabled):not(.disabled).active:focus,
.btn:not(:disabled):not(.disabled):active:focus,
.show>.btn.dropdown-toggle:focus {
    box-shadow: none;
}

.btn.focus,
.btn:focus {
    box-shadow: none;
}

.login-btn {
    border-radius: 2px;
    padding-left: 55px;
    font-weight: 400;
    font-size: var(--fs-14);
    height: 44px;
    position: relative;
}

.login-btn>span.microsoft-icon {
    background-color: #1f5dc2;
    border: 1px solid #1f5dc2;
    text-align: center;
    padding: 8.5px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.login-btn>span.google-icon {
    background-color: #b73d2a;
    border: 1px solid #b73d2a;
    text-align: center;
    padding: 8.5px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.login-btn>span.linkedin-icon {
    background-color: #216195;
    border: 1px solid #216195;
    text-align: center;
    padding: 8.5px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.login-btn>span.amazon-icon {
    background-color: #232f3e;
    border: 1px solid #232f3e;
    text-align: center;
    padding: 7.8px 12px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 16px;
}

.microsoft-btn {
    background-color: #2671ec;
    border-color: #2671ec;
}

.microsoft-btn:hover {
    background-color: #1a65df;
    border-color: #1a65df;
}

.google-btn {
    background-color: #df4a32;
    border-color: #df4a32;
}

.google-btn:hover {
    background-color: #d14028;
    border-color: #d14028;
}

.linkedin-btn {
    background-color: #2876b4;
    border-color: #2876b4;
    color: var(--ym-text-white-color);
}

.linkedin-btn:hover {
    background-color: #206fb1 !important;
    border-color: #206fb1;
    color: var(--ym-text-white-color);
}

.amazon-btn {
    background-color: #43474d;
    border-color: #43474d;
}

.amazon-btn:hover {
    background-color: #525860;
    border-color: #525860;
}

.signup-icon>div {
    padding: 5px 25px;
}

.divider-line {
    border-bottom: 1px solid var(--divider-default);
    width: 100%;
    margin: 55px 0;
    text-align: center;
    position: relative;
}

.divider-line>span {
    background-color: var(--ym-bg-white);
    padding: 5px 8px;
    display: block;
    font-weight: 700;
    font-size: var(--fs-14);
    position: absolute;
    left: 49%;
    top: -15px;
}

.op-card-box {
    width: 255.01px;
    height: 164px;
    border: 1px solid var(--border-default);
    box-sizing: border-box;
    border-radius: 5px;
    margin: auto;
    text-align: center;
    display: flex;
    align-items: center;
}

.op-card-box>img {
    margin: auto;
}

.op-card-box:hover {
    background: #eaf6ff;
    border: 3px solid #c7e9f4;
    cursor: pointer;
}

.header-divider {
    width: 100%;
    border-right: 1px solid #ccc;
    padding: 7px 0;
}

.ym-text-area {
    height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid var(--border-default);
    width: 100%;
    padding: 15px;
}

.home-frame-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.review-textbox-h {
    height: 152px;
}

.about-textarea {
    height: 96px;
}

/* Profile page CSS STARTS */
.profile-banner-box {
    border-radius: 5px;
    box-shadow: var(--ym-shadow-xs);
}

.profile-banner {
    height: 168px;
    background: linear-gradient(91.31deg,
            rgba(37, 85, 207, 0.5) 0%,
            rgba(71, 43, 195, 0.5) 44.63%,
            rgba(129, 158, 229, 0.5) 100%);
    border-radius: 5px 5px 0 0;
}

.profile-details {
    height: 128px;
    background: var(--ym-bg-white);
    border-radius: 0 0 5px 5px;
}

.profile-page {
    padding: 2% 0;
}

.profile-pic {
    width: 128px;
    height: 128px;
    border-radius: 50%;
    border: 4px solid var(--ym-bg-white);
    position: relative;
    top: -35px;
}

.profile-card {
    padding: 24px;
}

.box-out-pad {
    margin-top: 24px;
}

.ym-review-li {
    border-width: 1px 0;
    border-color: var(--divider-default);
    border-style: solid;
    padding: 15px 0;
}

/* profile page ends */

/* offer letter */
.page-layout {
    padding: 81px 61px 81px 61px;
}

.full-page-view {
    height: 1200px;
    overflow-y: auto;
}

.small-page-view {
    height: 1200px;
    overflow-y: auto;
}

.small-page-layout {
    padding: 20px 15px 20px 15px;
}

.ym-nav-tabs {
    border-bottom: 2px solid var(--divider-default);
}

.ym-offcanvas-end {
    width: 45% !important;
}

.ym-nav-bar {
    border-bottom: 3px solid var(--divider-default);
    margin-bottom: 10px;
}

.step-nav-link {
    background-color: transparent;
    border: 0;
    line-height: 6;
    padding: 0;
    font-size: var(--fs-12);
    color: #a19f9d;
}

.step-nav-link>div {
    border-radius: 50%;
    border: 2px solid var(--border-default);
    padding: 0px 6px;
    margin-right: 10px;
    display: inline-block;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 2;
    font-weight: var(--fw-400);
}

.step-nav-link.active {
    font-weight: var(--fw-700);
    color: var(--ym-text-primary);
}

.step-nav-link.active>div {
    background-color: #2bc04c;
    color: var(--ym-text-white-color);
    border: 1px solid #2bc04c;
}

.step-complete>div {
    background-color: #2bc04c;
    color: var(--ym-text-white-color);
    border: 1px solid #2bc04c;
}

.step-complete>div::before {
    content: '\f272';
    font-family: 'bootstrap-icons';
    color: var(--ym-text-white-color);
}

.step-complete>div>span {
    display: none;
    line-height: 2;
}

.li-review {
    border-bottom: 1px solid var(--divider-default);
    padding: 15px 0;
}

.card-body>.li-review:last-child {
    border-bottom: 0;
}

.dropdown-box {
    position: relative;
}

.ym-search-dropdown {
    position: absolute;
    width: 100%;
    background-color: var(--ym-bg-white);
    border: 1px solid var(--divider-default);
    box-sizing: border-box;
    box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1), 0px 3px 17px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    margin-top: 5px;
    padding: 15px;
}

.pi-circle-fill {
    top: -3px;
    position: relative;
}

.step-content-box {
    min-height: 530px;
}

.ym-icon-button {
    text-align: center;
    color: var(--ym-text-secondary);
    text-decoration: none;
}

.ym-icon-button>span {
    display: block;
}

.d-flex>.label {
    display: flex;
    align-items: center;
}

.items-selecter {
    padding: 3px 10px;
    border: 0;
    border-bottom: 1px solid #a19f9d;
    margin-left: 7px;
    outline: none;
    background-color: var(--ym-bg-white);
    color: var(--ym-text-primary);
}

.unplug-bg {
    /* background: url('../images/404.png') no-repeat; */
    background-position: center;
}

/* Landing Home */

section {
    padding: 75px 0;
}

.plan-bg {
    background-color: var(--ym-bg-white);
}

.gray-bg {
    background-color: var(--ym-bg-gray-10);
}

.ym-blue-bg {
    background-color: var(--ym-bg-white);
}

.hero-banner {
    padding: 75px 0;
}

.why-we-built {
    padding: 75px 0;
}

.align-left-border {
    border-top: 8px solid var(--border-primary-alt);
    width: 65px;
    margin-bottom: 24px;
}

.section-50 {
    padding: 50px 0;
}

/* About-us */
.about-us h1 {
    font-size: var(--fs-42);
    font-weight: var(--fw-700);
}

.about-us h2 {
    font-size: var(--fs-32);
    font-weight: var(--fw-600);
}

.aboutus-pic img {
    width: 80%;
}

section {
    padding: 80px 0px;
}

.company-description {
    margin-top: 80px;
}

.landing-footer {
    background: var(--ym-bg-footer);
    padding: 40px 0;
    color: #eaf6ff;
}

.landing-footer h4 {
    font-size: var(--fs-18);
    font-weight: var(--fw-700);
}

.yeldam-footer-address a {
    text-decoration: none;
    display: block;
    padding: 5px 0;
    color: #eaf6ff;
}

.yeldam-footer-links {
    text-decoration: none;
    padding: 0;
    list-style: none;
}

.yeldam-footer-links a {
    display: block;
    padding: 4px 0;
    color: #eaf6ff;
    text-decoration: none;
}

.yeldam-social-icons a {
    font-size: var(--fs-32);
    margin-right: 20px;
    color: #eaf6ff;
}

.landing-footer h4 {
    margin-bottom: 24px;
}

/* button:focus,
button {
    outline: 0 !important;
    box-shadow: none !important;
} */
.offcanvas-body .nav-link {
    padding-left: 24px !important;
    padding-right: 24px !important;
}

.notify-badge {
    top: 10px !important;
    left: 60%;
}

.profile-email {
    word-break: break-all;
}

/* .btn-primary {
    background-color: var(--ym-btn-primary) !important;
    border-color: var(--ym-btn-primary) !important;
} */
.btn-outline-primary {
    color: var(--ym-btn-outline-primary) !important;
    border-color: var(--ym-btn-outline-primary) !important;
}

.btn-outline-primary:focus,
.btn-outline-primary:hover {
    background-color: var(--ym-btn-outline-primary) !important;
    border-color: var(--ym-btn-outline-primary) !important;
    color: var(--ym-text-white-color) !important;
}

.btn-disabled {
    border-color: transparent !important;
    color: #a19f9d !important;
    background-color: var(--border-default) !important;
}

.ym-text-primary-default {
    color: var(--ym-text-primary-default);
}

.ym-header-line {
    width: 65px;
    height: 8px;
    background: var(--ym-text-primary-default);
    display: inline-block;
    margin: 0 0 24px 0;
}

a {
    color: var(--ym-text-primary-default);
}

.ym-dropdown-menu .dropdown-menu>li>button:hover,
.ym-dropdown-menu .dropdown-menu>li>button:active {
    background-color: var(--ym-bg-lighter-alt);
    color: #1e2125;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: var(--ym-bg-lighter-alt);
    color: #1e2125;
}

@media (max-width: 568px) {
    .offcanvas-end {
        width: 340px;
    }

    .section-50 {
        padding: 10px 0 !important;
    }
}

@media (max-width: 767px) {
    .about-us h1 {
        font-size: var(--fs-28) !important;
    }

    .about-us h2 {
        font-size: var(--fs-20) !important;
    }

    section.tryyeldam {
        background-image: none !important;
        padding: 60px 0px !important;
    }
}

@media (max-width: 992px) {
    .landing-footer h4 {
        margin-top: 24px;
        margin-bottom: 16px;
    }

    .offcanvas-end .nav-link {
        padding: 10px 0 !important;
    }

    .thumb-img {
        width: 75%;
    }

    .aboutus-pic img {
        margin-top: 30px !important;
        width: 100% !important;
    }

    .rebalancing-pic,
    .company-one {
        margin-top: 40px;
    }

    .company-description {
        margin-top: 0px;
    }
}

@media (min-width: 1200px) {
    .ym-contact {
        padding: 0 80px;
        border-right: 1px solid var(--border-default);
    }
}

.form-control {
    border: 1px solid var(--border-default);
}

.table-striped>tbody>tr:nth-of-type(even) {
    --bs-table-accent-bg: var(--ym-bg-gray-10);
    background: var(--ym-bg-gray-10);
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--ym-bg-white);
    background: var(--ym-bg-white);
}

.table-striped>tbody>tr td {
    border-color: var(--divider-default);
    box-shadow: none;
    vertical-align: middle;
}

.table-striped>tbody>tr td img {
    width: 48px;
    height: 48px;
}

.table> :not(:last-child)> :last-child>* {
    border-color: var(--divider-default);
}

.offcanvas-header {
    align-items: start;
}

.contact-pic img {
    width: 80%;
}

/*dark theme Css */
.navbar-light .navbar-brand,
.nav-link.text-dark,
.nav-link.text-dark .text-dark {
    color: var(--ym-text-primary) !important;
}

.bg-white,
.modal-content,
.card,
.profile-details,
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link,
.accordion-header,
.accordion-button,
.accordion-item {
    background-color: var(--ym-bg-white) !important;
}

.list-group-item {
    background-color: var(--ym-bg-white) !important;
    border-color: var(--border-default);
}

.offer-select--header .react-select__control {
    border-color: var(--border-default) !important;
    background-color: var(--ym-bg-white) !important;
}

.form-control,
.page-select .react-select__control,
.form-select,
.offer-select .react-select__control,
.react-datepicker__input-container input {
    color: var(--ym-text-primary) !important;
    background-color: var(--ym-bg-form-color) !important;
    border-color: var(--border-default) !important;
}

.navbar-light .navbar-toggler,
.dropdown-item {
    color: var(--ym-text-primary) !important;
}

.navbar-light .navbar-toggler {
    background-color: var(--ym-bg-lighter);
}

.dropdown-menu {
    background-color: var(--ym-bg-white) !important;
    color: var(--ym-text-primary) !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
    background-color: var(--ym-bg-lighter-alt) !important;
    color: var(--ym-text-primary) !important;
}

.offcanvas.show {
    background-color: var(--bs-offcanvas-bg) !important;
}

.react-select__control .react-select__single-value {
    color: var(--ym-text-primary) !important;
}

table {
    color: var(--ym-text-primary) !important;
}

table td,
.ym-action-btn.text-black,
.react-datepicker__day {
    color: var(--ym-text-primary) !important;
}

.react-datepicker,
.react-datepicker__header {
    background-color: var(--ym-bg-white) !important;
    color: var(--ym-text-primary) !important;
}

.react-datepicker__day.react-datepicker__day--in-range,
.react-datepicker__day.react-datepicker__day--in-selecting-range {
    background-color: var(--ym-bg-lighter-alt) !important;
}

.react-datepicker__week .react-datepicker__day--range-start,
.react-datepicker__week .react-datepicker__day--range-end,
.react-datepicker__week .react-datepicker__day--selecting-range-start,
.react-datepicker__week .react-datepicker__day--selecting-range-end {
    background-color: var(--ym-bg-primary) !important;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day:hover {
    background-color: var(--ym-bg-footer) !important;
}

.react-datepicker-header__btn {
    background-color: var(--ym-bg-white) !important;
}

.react-datepicker__day--disabled {
    color: var(--ym-text-secondary) !important;
}

.offer-select .react-select__input-container {
    color: var(--ym-text-primary) !important;
}

.react-datepicker-header--range {
    border-color: var(--divider-default) !important;
}

.offer-select .react-select__option,
.react-select__menu div {
    background-color: var(--ym-bg-white) !important;
    border-color: var(--border-default) !important;
}

.react-select-menu-btn {
    background-color: var(--ym-bg-white) !important;
}

.offer-select .react-select__option:focus,
.offer-select .react-select__option:hover .offer-select .react-select__option:focus div,
.offer-select .react-select__option:hover div {
    background-color: var(--ym-bg-lighter-alt) !important;
}

.offer-select .react-select__option:focus-visible,
.offer-select .react-select__option:focus-within,
.offer-select .react-select__option:target,
.offer-select .react-select__option:visited,
.offer-select .react-select__option:active {
    background-color: var(--ym-bg-gray-10) !important;
}

.react-select__menu .react-select__option {
    background-color: var(--ym-bg-white) !important;
}

.react-select__menu .react-select__option:focus,
.react-select__menu .react-select__option:hover {
    background-color: var(--ym-bg-lighter-alt) !important;
}

.react-select__menu .react-select__option:focus-visible,
.react-select__menu .react-select__option:active,
.react-select__menu .react-select__option:focus-visible div,
.react-select__menu .react-select__option:active div {
    background-color: var(--ym-bg-primary) !important;
}

.btn-close {
    background: none;
}

.btn-close:before {
    content: '\F659';
    font-family: 'bootstrap-icons';
    color: var(--ym-text-primary);
    font-size: var(--fs-18);
}

.react-datepicker__input-container {
    position: relative;
}

.react-datepicker__input-container::before {
    content: '\F214';
    font-family: 'bootstrap-icons';
    color: var(--ym-text-primary);
    position: absolute;
    top: 5px;
    right: 10px;
}

.react-datepicker__input-container input {
    background-image: none !important;
}

.react-datepicker__input-container input::before {
    content: '\F1F6';
    font-family: 'bootstrap-icons';
    color: var(--ym-text-primary);
    font-size: var(--fs-18);
}

.btn-link {
    color: var(--ym-text-primary-default);
}

.ym-profile-dropdown .border-bottom {
    border-color: var(--divider-default) !important;
}

/* .card {
    box-shadow: 0px 0.3px 0.9px rgb(0 0 0 / 10%), 0px 1.6px 3.6px rgb(0 0 0 / 13%);
} */
.ym-card {
    border-radius: 1rem;
}

@media (min-width: 992px) {
    .ym-modal-dialog-md {
        min-width: 600px !important;
    }
}

#chartContainer2 {
    min-height: 220px !important;
}

.ym-review-dot {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 3px;
}

.ym-table-border.table> :not(caption)>*>* {
    border-color: #ececec;
    padding: 0.85em 1.5em;
}

.ym-progress-height-5 {
    height: 5px;
    display: none;
}

.ym-nav-bar2 .nav-tabs .nav-link {
    border: 1px solid #ececec;
    border-radius: 4px;
    margin: 2px;
}

.ym-nav-bar2 .nav-tabs .nav-link.active {
    border-color: var(--ym-text-primary-alt);
}

.ym-step-number {
    width: 2.2em;
    height: 2.2em;
    display: block;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    line-height: 2.2em;
    text-align: center;
    color: #fff;
}

.ym-time-line {
    position: relative;
}

.ym-time-line:before {
    content: '';
    width: 1px;
    height: 100%;
    position: absolute;
    left: 20px;
    z-index: 1;
    display: inline-block;
    border-left: 2px dotted #d2d2d2
}

.ym-time-line li {
    position: relative;
    margin-bottom: 20px;
    z-index: 9;
}

.row-deck>.col,
.row-deck>[class*='col-'] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.row-deck .card {
    width: 100%;
}

.ym-social-card-header i {
    font-size: var(--fs-32);
}

.ym-social-card-header {
    padding: 9px;
    background-repeat: inherit;
    /* background-image: url(../images/bg.png); */
    position: relative;
    background-size: cover;
}

.ym-social-card-header::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
}

.ym-social-card-header i {
    position: relative;
    z-index: 9;
}

.ym-facebook-header:before {
    background-color: rgb(66 103 178 / 70%);
}

.ym-instagram-header:before {
    background-color: rgb(193 53 132 / 70%);
}

.ym-linkedin-header:before {
    background-color: rgb(0 119 181 / 70%);
}

.ym-twitter-header:before {
    background-color: rgb(29 161 242 / 70%);
}

.ym-icon-circle {
    width: 2.2em;
    height: 2.2em;
    display: block;
    border-radius: 50%;
    font-size: 18px;
    font-weight: 600;
    line-height: 2.2em;
    text-align: center;
    color: var(--ym-text-white-color);
}

.ym-alert-bg-green {
    background-color: var(--ym-bg-success);
}

.ym-text-green {
    color: var(--ym-bg-success) !important;
}

.ym-data-absolute {
    position: absolute;
    z-index: 99;
    width: 110%;
    background: var(--ym-text-white-color);
    padding: 20px;
    left: -1.5rem;
    border: 1px solid rgba(0, 0, 0, .125);
    border-top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    top: 35px;
}

/* .ym-shape-card-agents{
    position: relative;
    overflow: hidden;
} */
/* .ym-shape-card-approved{
    position: relative;
    overflow: hidden;
} */
/* .ym-shape-card-pending{
    position: relative;
    overflow: hidden;
} */
/* .ym-shape-card-approved{
    position: relative;
    overflow: hidden;
} */
/* .ym-shape-card-rejected{
    position: relative;
    overflow: hidden;
} */
.ym-shape-card-agents:before {
    content: '';
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background: #35d5f1;
    left: 20px;
    top: 20px;
    color: var(--ym-bg-lighter);
    opacity: 0.2;
    transform: rotate(0deg);
}

.ym-shape-card-approved:before {
    content: '';
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background: #6ff85b;
    left: 20px;
    top: 20px;
    color: var(--ym-bg-lighter);
    opacity: 0.2;
    transform: rotate(0deg);
}

.ym-shape-card-pending:before {
    content: '';
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background: #ffd236;
    left: 20px;
    top: 20px;
    color: var(--ym-bg-lighter);
    opacity: 0.2;
    transform: rotate(0deg);
}

.ym-shape-card-rejected:before {
    content: '';
    position: absolute;
    width: 65px;
    height: 65px;
    border-radius: 5px;
    background: #ff8257;
    left: 20px;
    top: 20px;
    color: var(--ym-bg-lighter);
    opacity: 0.2;
    transform: rotate(0deg);

}

.ym-nav-bar .nav-link {
    cursor: pointer;
}

@media (max-width:1440px) {
    .ym-star-review-card .bi {
        font-size: 12px;
        margin-right: 2px;
    }

    .ym-star-review-card .fs-16 {
        font-size: var(--fs-14) !important;
    }

    .ym-score-review-text {
        display: none;
    }

}

@media (min-width:1366px) and (max-width:1440px) {

    .row-xl-deck>.col,
    .row-xl-deck>[class*='col-'] {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: stretch;
        align-items: stretch;

    }

}

.w-160 {
    width: 160px;
}

.ym-bg-alert-darker {
    background-color: var(--ym-bg-alert-darker);
}

.ym-today-date {
    padding: 14px 24px;
    background-color: rgba(0, 150, 255, 0.08);
    border-radius: 0.5rem;
}

/*ticket-support*/
.ym-tick-support-list a {
    cursor: pointer;
}

.ym-tick-support-list a:hover,
.ym-tick-support-list a.active {
    background-color: #F9F9F9;
}

.ym-ticket-subject {
    padding: 1.5em;
    border-radius: 0.25em;
    background-color: #F9f9f9;
}

.ym-comment .avatar {
    height: 40px;
    width: 40px;
}

.ym-review-list img {
    height: 56px;
    width: 56px;
}

.ym-form-value {
    line-height: 30px;
}


.yeldam_left_nav {
    width: 15rem !important;
    background-color: var(--ym-bg-white);
    position: fixed;
    height: 100%;
    z-index: 10;
    padding: 16px;
    overflow-y: scroll;
}

.yeldam_nav_links .nav-link {
    padding: 8px;
    border-radius: 6px;
    margin: 8px 0;
    color: var(--ym-text-primary);
    font-size: var(--fs-14);
    width: 100%;
}

.yeldam_nav_links .nav-link.active,
.yeldam_nav_links .nav-link:hover {
    font-weight: 500;
    background-color: var(--ym-bg-primary-50);
    color: var(--ym-bg-primary-600) !important;
    cursor: pointer !important;
}

.yeldam-nav-bottom-link:hover {
    background-color: var(--ym-bg-primary-50) !important;
}

.yeldam_nav_links .nav-link i {
    margin-right: 10px;
}

.yeldam-logo-link {
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 8px 0px !important;
}

.yeldam-nav-bottom-link {
    border-top: 1px solid var(--divider-default);
    /* padding-top: 20px !important; */
}

.yeldam-agent-header {
    padding: 10px;
    display: flex;
    border-bottom: 1px solid var(--divider-default);
    background-color: var(--ym-bg-white);
}

@media (min-width:992px) {
    .offcanvas {
        visibility: visible !important;
        transform: inherit !important;
    }

    .yeldam-agent-header {
        display: none;
        ;
    }
}

@media (max-width:992px) {
    .page-content {
        padding-left: 1em !important;
    }

    .offcanvas {
        z-index: 9999;
    }

    .lt_yeldam_accordion .accordion-button:not(.collapsed),
    .lt_yeldam_accordion .accordion-button h2 {
        line-height: 1.5;
        margin-bottom: 10px;
    }
}

.page-content {
    overflow-y: auto !important;
    overflow-x: hidden;
    padding: 1.5em 1em 1em 15em;
}

h1 {
    font-size: var(--fs-28) !important;
    font-weight: 700 !important;
    line-height: 36px !important;
    margin-top: 15px;
    margin-bottom: 20px;
}

.date-range {
    width: 150px;
    margin-top: 8px;
}

.form-select {
    border-radius: 6px;
}

.ym-dashboard-text {
    /* color:var( --ym-text-blue); */
    color: #818181;
    font-family: "Rubik";
}



.ym-dashboard-rest-icon {
    position: relative;
    right: -40px;
    top: 10px;
    color: #8CD8DD
}

.ym-dashboard-pending-icon {
    position: relative;
    right: -40px;
    top: 10px;
    color: #f0c226
}

.ym-dashboard-approve-icon {
    position: relative;
    right: -40px;
    top: 10px;
    color: #A5D99E
}

.ym-dashboard-reject-icon {
    position: relative;
    right: -40px;
    top: 10px;
    color: #fa7747
}

/* .ym-dashboard-rest-icon{position: absolute;right: 10px;top: 35px;color:#8CD8DD} */
/* .ym-dashboard-approve-icon{position: absolute;right: 10px;top: 35px;color:#A5D99E} */
/* .ym-dashboard-pending-icon{position: absolute;right: 10px;top: 35px;color:#AE8701} */
/* .ym-dashboard-reject-icon{position: absolute;right: 10px;top: 35px;color:#D83B01} */

.chart-view .form-check-input:focus {
    box-shadow: none;
}

.chart-view .form-check-input {
    border-radius: 0px;
    font-size: 24px;
}

#inlineCheckbox1 {
    border: 2px solid #0096FF;
}

#inlineCheckbox2 {
    border: 2px solid #2BC04C;
}

#inlineCheckbox3 {
    border: 2px solid #D83B01;
}

#inlineCheckbox4 {
    border: 2px solid #F0C916;
}

.chart-view .form-check-label {
    font-size: var(--fs-14) !important;
    font-weight: 700 !important;
    line-height: 20px !important;
    margin-top: 8px;
    margin-right: 30px;
}

.table-responsive th {
    vertical-align: top;
}

.table-responsive td {
    vertical-align: middle;
}

.table>:not(:first-child) {
    border-top: 1px;
}

.form-date-width {
    width: 16em;
    ;
}

.ym-text-yellow {
    color: var(--ym-alert-bg-warning-fill);
}

.hover-icons i {
    visibility: hidden;
    padding: 0 5px;
}

.hover-icons a:last-child i {
    padding-right: 0;
}

.hover-icons .ym-flag-icon i {
    visibility: visible !important;
    color: var(--ym-text-error);
}

.table-hover>tbody>tr:hover .hover-icons i {
    visibility: visible;
}

.lt_yeldam_Suspend_accordion .accordion-button {
    padding-left: 0;
    padding-right: 0;
    font-weight: var(--fw-600);

}

.lt_yeldam_Suspend_accordion .accordion-button:not(.collapsed) {
    background-color: transparent;
    color: var(--ym-text-primary);
}

.lt_yeldam_Suspend_accordion .accordion-body {
    padding-top: 0;
}

.avatar-lg {
    width: 72px !important;
    height: 72px !important;
}

.ym-dropdown-border a.btn {
    border: 1px solid var(--border-default) !important;
    padding: 0.375rem 0.75rem;
    border-color: var(--border-default);
}

.ym-dropdown-border>.ym-dropdown-menu>a.btn.btn-secondary {
    border-color: var(--border-default);
}

.positive-relative {
    position: relative;
}

.lt_yeldam_accordion .accordion-button:not(.collapsed),
.lt_yeldam_accordion .accordion-button h2 {
    background-color: transparent;
    color: var(--ym-text-primary);
}

.ym-reply-li {
    padding: 0px 0px 15px;
}

.ym-badge {
    background-color: var(--divider-default);
    color: var(--ym-text-secondary);

}

.ym-review-li:last-child {
    border-style: none;
}

.card .ym-text-success {
    color: var(--ym-text-success) !important;
}

.card .ym-text-error {
    color: var(--ym-text-error) !important;
}

a {
    text-decoration: none;
}

.modal .btn-outline-primary:focus,
.modal .btn-outline-primary:hover {
    background-color: transparent !important;
}

.site-logo {
    height: 52px !important;
}

.accordion-item .accordion-button::after {
    content: '\F282';
    font-family: 'bootstrap-icons';
    color: var(--ym-text-primary);
    background-image: none;
}

.accordion-button {
    color: var(--ym-text-dark-theme);
}

.navbar-toggler .pi-list {
    color: var(--ym-text-primary);
}

.star-icon {
    margin-bottom: 10px;
}

@media (min-width:375px) {
    .star-icon .bi {
        margin-right: -2px;
    }
}

.ym-content-table {
    margin: 15px !important;
}

.ym-dashboard-deck {
    padding-right: 1.1rem !important;
}

.p-column-filter-row {
    margin-bottom: 10px !important;
}

.pi-filter-slash:before {
    display: none !important;
}

.p-column-filter-row .p-column-filter-element {
    width: 80% !important;
}

.offcanvas.offcanvas-start {
    border-right: var(--bs-offcanvas-border-width) solid rgb(0 0 0 / 3%) !important;
}

/* .p-chart{
    height: 39vh !important;
    width: 50vw !important;
} */

.leaflet-container {
    height: 600px;
    width: 100%;
}

.leaflet-div-icon {
    background: transparent;
    border: none;
}