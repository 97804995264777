.drop-file-input {
  position: relative;
  width: 400px;
  height: 200px;
  border: 2px dashed #606060 !important;
  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
}

.drop-file-input input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
  opacity: 0.6;
}

.drop-file-input__label {
  text-align: center;
  color: #000;
  font-weight: 600;
  padding: 10px;
}

.drop-file-input__label img {
  width: 100px;
}

.drop-file-preview {
  margin-top: 30px;
}

.drop-file-preview p {
  font-weight: 500;
}

.drop-file-preview__title {
  margin-bottom: 20px;
}

.drop-file-preview__item {
  position: relative;
  display: flex;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 15px;
  border-radius: 20px;
}

.drop-file-preview__item img {
  width: 50px;
  margin-right: 20px;
}

.drop-file-preview__item__info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.Supports {
  color: #A19F9D !important;
  font-weight: 400 !important;

}

.Drag {
  color: #605E5C !important;
  font-weight: 400 !important;

}

/* Hide the default file input */
#upload-input {
  display: none;
}

#upload-input-ThumbnailImages {
  display: none;
}
#upload-input-storeThumbnailImages {
  display: none;
}
#upload-input-RestaurantImages {
  display: none;
}
#upload-input-Storeimages{
  display: none;
}
#upload-input-MenuImages {
  display: none;
}

#upload-input-StoreThumbnail {
  display: none;
}

#upload-input-StoreImages {
  display: none;
}

/* .card {
    position: relative;
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .icon-container {
    display: flex;
    justify-content: center;
    background: red !important;
    color:white;
    border-radius:100px !important; 
    align-items: center;
    margin-top: -30px;
    position: relative;
  }
  
  .p-icon {
    font-size: 2rem;
    color: #fff;
    background-color: #007ad9;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }  */
.icontrash {
  background-color:#fff !important;
  color: #3c3c3c !important;
  font-size: 12px !important;
  padding: 5px !important;
  border-radius: 20px !important;
}

/* #Imagecrd{
  width: 90px !important;
  height: 90px !important;
} */
.uploadimage{
  width: 118px ;
  height: 118px ;
  border-radius: 20px;
}
.upload-image-box{
  position: relative !important;
  width: 150px !important;
  height: 120px !important;
  border: 2px dashed #606060 !important;
  border-radius: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #fff !important;
}
.upload-label{
font-family: "Roboto";
font-size: 12px;
font-weight: 500;
line-height: 14.06px;
letter-spacing: 0.25px;
text-align: center;
color: #3c3c3c;
}
#upload-input-medicalregistrationprooffile{
  display: none !important;
}
#upload-input-DietitianImage{
  display: none !important;
} 
#upload-input-clinicimages{
  display: none !important;

}