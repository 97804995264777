.p-datatable .p-datatable-thead>tr>th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}

.view-bt {
    color: #000000 !important;
    background: #689f3800 !important;
    border: 0px !important;
}

.page_title {
    margin-bottom: 20px !important;
}

.approved-action_btn .p-button {
    color: #ffffff;
    background-color: #ffffff !important;
    border: none !important;
    padding: 0.5rem 1rem;
    font-size: 12px !important;
    transition: 0s !important;
}

.Approved-view-btn {
    background-color: #f8f8f8 !important;
    border: 1px solid #f8f8f8 !important;
    font-family: "Roboto" !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 14.06px !important;
    letter-spacing: 0.25px !important;
    color: #818181 !important;
    padding: 8px !important;
    display: flex !important;
    align-items: center !important;
}
.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltip-icon {
    cursor: pointer;
  }
  
  .tooltip-box {
    position: absolute;
    bottom: 100%;
    right: 0;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ececec;
    z-index: 1;
  }
  .details-tooltip-box {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ececec;
    z-index: 1;
  }
  
  .tooltip-button {
    display: block;
    width: 100%;
    padding: 8px;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    color: #3c3c3c;
  }
  
  .tooltip-button:last-child {
    margin-bottom: 0;
  }
  
  .tooltip-button:hover {
    background-color: #f8f8f8;
  }
  .p-inputswitch {
    width: 42px !important;
    height: 24px !important;
}
.p-inputswitch .p-inputswitch-slider:before {
  left: 0.005rem !important;
  transition-duration: 0s !important;
}
