.rest-img,.rest-img img{
    width: 118px;
    height: 118px;
    border-radius: 10px;
    margin-right: 30px;
}

.rest-img .p-image-preview-indicator{
    border-radius: 10px !important;
    width: 118px !important;
    height: 118px !important;
}
.image_gallery{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}