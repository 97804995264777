@charset "utf-8";
@import "colors.css";

body {
  background: var(--ym-bg-white) !important;
  font-family: var(--font-family) !important;
}
a{
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6,body{
  font-family: var(--font-family) !important;
}
p {
  font-family: 'Roboto', sans-serif;
}

/* a:hover {
  color:inherit !important;
} */
header{
  z-index: 997;
  transition: all 0.5s;
  padding: 12px 0;
  background:  var(--ym-bg-white);
}
#header.header-scrolled {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  padding: 12px 0;
}
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--fs-14) !important;
  font-weight: 400;
  color: var(--ym-text-typo-800);
  white-space: nowrap;
  transition: 0.3s;
  font-family: 'Roboto', sans-serif;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: var(--ym-text-primary-400);
}
.navbar .active:before {
  content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: -12px;
    left: 0;
    border-bottom: 2px solid var(--ym-bg-primary-400);
}
/* Cards */
.ym-card {
  border: 1px solid var(--divider-border);
  background-color: var(--ym-bg-typo-900);
  border-radius: 6px;
}
.ym-card img{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.ym-card-body {
  padding: 1rem;
}

.footer-links li{
  list-style: none;
}

ul.footer-links {
  padding-left: 0;
}

.footer-links li a{
   font-size: var(--fs-14);
   font-weight: var(--fw-400);
   color:var(--ym-bg-typo-600);
   font-family: 'Roboto', sans-serif;
  }
  
  .social-links i{
    font-size: var(--fs-24) !important;
    color: var(--ym-bg-typo-600);
    margin-right: 16px;
  }
/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #323130;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.pi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar .active:before,.navbar ul {
    display: none;
  }
  .navbar-mobile ul {
    padding: 10px !important;
    border-radius: 0px !important;
    /* overflow-y: auto; */
    height: fit-content;
}
.navbar li{
	border-bottom: 1px solid #ececec;
}
.navbar li:last-child{
	border-bottom: 0px solid #ececec !important;
}
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(9, 9, 9, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 8px;
  background-color: var(--ym-bg-white);
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: var(--ym-text-typo-800);
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: var(--ym-text-primary-400);
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
