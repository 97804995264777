.event-span {
    color: #000;
}
.event-span, .home-span {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}
.home-span {
    color: #818181;
}
.settings-menu{
    width: 14.5rem  !important;
}


.settings-menu-item.active, .settings-menu-item:hover{
    font-weight: 500;
    background-color: var(--ym-bg-primary-50);
    color: var(--ym-bg-primary-600) !important;
}
.settings_nav_links .settings-menu-item {
    padding: 10px;
    border-radius: 6px;
    margin: 8px 10px;
    color: var(--ym-text-primary);
    font-size: var(--fs-14);
    width: 100%;
}
.settings_nav_links{
    border-radius: 15px;
}


