.p-datatable .p-datatable-thead>tr>th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}

.view-bt {
    color: #000000 !important;
    background: #689f3800 !important;
    border: 0px !important;
}

.p-paginator {
    border: none !important;
}

.p-datatable .p-datatable-tbody>tr>td {
    font-size: 14px;
    font-weight: 400;
    color: #3c3c3c !important;
    font-family: "Roboto" !important;

}

.event-span-head {
    font-family: "Rubik" !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c;
}

.delete-model .p-dialog-header {
    display: none !important;
}

.delete-model .p-dialog-content {
    border-radius: 12px !important;
    padding: 0 1.5rem 1.5rem 1.5rem !important;
}

.p-dialog {
    border-radius: 12px !important;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
    border: 0 none;
}

.delete-icon-bg {
    border-radius: 50%;
    background-color: #FA410033 !important;
}

.delete-icon-bg .pi-trash::before {
    width: 26px !important;
    height: 30px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.d-head {
    font-family: "Rubik" !important;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.7px;
    letter-spacing: 0.15000000596046448px;
    color: #3c3c3c !important;
    text-align: center !important;
}

.d-caption {
    font-family: "Roboto" !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #3c3c3c !important;
}

.no_btn_dlt {
    width: 80px !important;
    border: 1px solid #36a41d !important;
    color: #36a41d !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    background-color: #ffffff !important;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}
.yes_dlt_btn{
    width: 139px !important;
    border: 1px solid #D83B01 !important;
    color: #ffffff !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.5px !important;
    background-color: #D83B01 !important;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}


