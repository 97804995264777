.p-datatable .p-datatable-thead > tr > th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}
.view-bt {
    color: #000000 !important;
    background: #689f3800 !important;
    border: 0px !important;
}
.page_title {
    margin-bottom: 16px!important;
}
a {
    color: #818181 !important;
}