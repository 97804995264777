.p-datatable .p-datatable-thead>tr>th input {
    padding: 6px 5px !important;
    padding-left: 10px !important;
}

.view-bt {
    color: #000000 !important;
    background: #689f3800 !important;
    border: 0px !important;
}

.p-inputswitch .p-inputswitch-slider {
    background: #c2c2c2 !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none !important;
}
.add-btn-login {
    width: 150px;
    background-color: #36a41d !important;
    border-color: #36a41d !important;
    color: #fff !important;
}

.add_agent_btn {
    width: 120px !important;
    height: 32px !important;
    border-radius: 4px !important;
    background-color: #36a41d !important;
    color: #ffffff !important;
    white-space: nowrap !important;
    border: none !important;
    font-family: "Roboto" !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    letter-spacing: 0.5px !important;

}