/* *{
  align-items: flex-start;
} */

.img {
  display: flex;
  width: 250px !important;
  height: 250px !important;
  padding-right: 10px;
}

.Rest-img {
  display: flex;
}

.back_rest_btn {
  font-family: "Rubik";
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0.15000000596046448px;
  color: #3C3C3C;
  cursor: pointer;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: none;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #36a41d !important;
  background: #ffffff;
  border-color: #36a41d !important;
}

.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: #36a41d;
}

.RestName h5 {
  /* position: absolute; */
  width: 1110px;
  height: 28px;
  left: 285px;
  top: 36px;
  margin-bottom: 15px;

  /* Medium/H5 */
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  /* align-items: center; */

  /* Type/800 */

  color: #3c3c3c;
}

.Reject {
  background-color: #ffffff;
  border: 1px solid #818181;
  width: 142px;
  height: 40px;
  justify-content: center;
  align-items: center;
  color: #818181;
  border-radius: 51px;
}

.Reject:enabled:hover {
  background-color: transparent;
  color: #818181;
  border-color: #818181;
}

.p-button:focus {
  box-shadow: none;
  outline: none;
}

.Accept {
  justify-content: center;
  align-items: center;
  padding: 12px 16px;

  width: 142px;
  height: 40px;

  /* Primary/600 */

  background: #36a41d;
  border-radius: 52px;
  border-color: #36a41d;
}

.Accept:enabled:hover {
  background-color: #36a41d;
  border-color: #36a41d;
}

.header {
  /* position: absolute;
  height: 87px;
  background-color: #FFFFFF;
  justify-content: center; */
  /* Frame 46898 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 44px 8px 45px;
  gap: 8px;

  position: absolute;
  width: 1200px;
  height: 87px;
  /* left: 0px;
  top: 0px; */

  /* Type/white */

  background-color: #ffffff;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: none !important;
}

.change-btn-login {
  width: auto;
  background-color: #36a41d !important;
  border-color: #36a41d !important;
  color: #fff !important;
  margin-left: 10px !important;
  border-radius: 4px !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 14px !important;

}

.edit_btn {
  width: auto !important;
  color: #818181 !important;
  background-color: #FAFAFA !important;
  border-radius: 4px !important;
  border: 1px solid #ececec !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

.reject_btn {
  width: auto !important;
  background-color: #ffffff !important;
  border-color: #D83B01 !important;
  color: #D83B01 !important;
  border-radius: 4px !important;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
.footer-text{
  font-family: "Rubik" !important;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.7px;
  letter-spacing: 0.15000000596046448px;
  text-align: center;
color: #3C3C3C;
margin-bottom: 8px;
}
.approve-div{
font-family: "Roboto";
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
letter-spacing: 0.5px;
text-align: center;
color: #3C3C3C;
margin-bottom: 0px;

}
.disabled_reject_btn{
  background-color: #C2C2C2 !important;
  border: 1px solid #C2C2C2 !important;
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16.41px !important;
letter-spacing: 0.5px !important;
color: #ffffff !important;
cursor:  pointer !important;
}
.yes_reject_btn{
  background-color: #D83B01 !important;
  border: 1px solid #D83B01 !important;
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16.41px !important;
letter-spacing: 0.5px !important;
color: #ffffff !important;
cursor: pointer !important;
}
.reason_head{
font-family: "Roboto" !important;
font-size: 14px;
font-weight: 500;
line-height: 16.41px;
letter-spacing: 0.5px;
color: #3c3c3c;
margin-bottom: 4px !important;
}
.status_change_div .p-dialog-header {
  display: none !important;
}
.status_change_div .p-dialog-content {
  padding: 24px !important;
  border-radius: 12px 12px 0px 0px !important;
}
.status_change_div .p-dialog-footer {
  border-radius: 0px 0px 12px 12px !important;
}
.aprjpic{
  width: 64px;
  height: 64px;
}
.cancel_btn{
  border: 1px solid #36a41d !important;
  border-radius: 4px !important;
font-family: "Roboto" !important;
font-size: 14px !important;
font-weight: 500 !important;
line-height: 16.41px !important;
letter-spacing: 0.5px !important;
color: #36a41d !important;
}

.change-btn-login:hover {
  background: #1e7c08 !important;
  color: #ffffff !important;
  border-color: #5e8f32 !important;
}

.pi-chevron-left {
  cursor: pointer;
}

.p-image-preview-container>img,
.p-image-preview-container {
  object-fit: cover;
  width: 100%;
  height: 180px;
}

.p-image-preview-container:hover>.p-image-preview-indicator {
  opacity: 1;
  cursor: pointer;
  border-radius: 20px;

}

.p-image-preview-container>img,
.p-image-preview-container {
  object-fit: cover;
  width: 255px;
  height: 180px;
  border-radius: 20px;
  margin-right: 15px !important;
}

.view-gallery-bt {
  color: rgb(255, 255, 255);
  padding: 0px;
  margin: 0.8rem 0px 0.8rem 1rem;
  font-size: 14px;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.2;
  z-index: 1;
}

.view-gallery-col-2 {
  font-size: 16px;
  font-family: "Rubik";
  line-height: 1.5;
  margin: 0px;
  font-weight: 500;
  position: relative;
  color: rgb(255, 255, 255);
  top: -6.5rem;
  left: 0px;
  width: 100%;
  text-align: center;
}

.view-gallery-col-2 .p-button.p-button-text {
  color: #ffffff !important;
}

.view-gallery-col-2 .p-button.p-button-text:enabled:active {
  background: transparent !important;
}

.view-gallery-col-2 .p-button:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.image-filter-col img {
  filter: brightness(0.4);
}

/* .p-image-preview-indicator {
  border: 1px solid rgb(222, 222, 222) !important;
  border-radius: 130px !important;
} */

.rest_title {
  width: auto;
  height: auto;
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 400;
  color: #3C3C3C;
}

.ym-portal-heading {
  font-family: "Rubik" !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  line-height: 23.7px !important;
  letter-spacing: 0.15px !important;
  color: #1E1E1E;
  margin-bottom: 0px;
}

.address-heading {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #3c3c3c;
  margin-bottom: 6px;
  font-family: "Roboto" !important;
}

.area-data {
  /* font-family: "Roboto"; */
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.5px;
  color: #818181;

}

.agent-added {
  width: max-content;
  padding: 12px;
  height: 24px;
  background-color: #E8F6E6;
  border-radius: 32px;
}

.agent-added p {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.25px;
  color: #36a41d;

}

.rest-details .rest_title {
  margin-bottom: 10px !important;
}


.rest-address p {
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #818181;
  margin-bottom: 8px;
}

.Rest-timing p {
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.agent-disp {
  font-family: 'Rubik' !important;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #818181;
}

.view-gallery-col-2 .p-button {
  font-family: 'Rubik' !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}