.ym-loading-overlay {
    position: absolute;
	background-color: rgba(0, 0, 0, 0.325);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

.ym-component-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}