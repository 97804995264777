.save-btn-login {
    width: 150px;
    background-color: #36a41d !important;
    border-color: #36a41d !important;
    color: #fff !important;
}

.error {
    color: #D83B01;
    font-family: "Roboto" !important;
    font-size: 12px;
    font-weight: 400;
    line-height: 16.41px;
    letter-spacing: 0.5px;
}
.Agent-details-dialog .p-dialog-header {
    border-radius: 12px 12px 0px 0px !important;
    padding: 1.5rem !important;
}
.Agent-details-dialog .p-dialog-footer {
    border-radius: 0px 0px 12px 12px !important;

}

.add-agent-header{
font-family: "Roboto" !important;
font-size: 14px;
font-weight: 400;
line-height: 16.41px;
letter-spacing: 0.5px;
color: #3c3c3c !important;
margin-bottom: 4px !important;
}
.add-agent-inputfield  {
height: 44px !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none !important;
}
