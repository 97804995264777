.main-login {
  background-color: #F5f5f5;
}

.card {
  padding: 20px;
}

.content-details {
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  align-items: center;
  width: 440px !important;
  height: 640px !important;
  height: auto;
  border: none;
}

.content {
  font-weight: 400;
  font-size: 14px;
  color: #818181;
}

.admin {
  font-size: 20px;
  font-family: "Rubik";
  font-weight: 600;
  color: #3c3c3c
}

.image-section {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.otp-head {
  color: #818181;
  font-weight: 400;
  font-size: 13px;

}

.otp-head .mail {
  color: #212529;
  font-size: 14px;
  font-weight: 400;
}

.otp-subhead {
  font-size: 16px;
  text-align: center;
}

.login-btn-microsoftbutton {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 0px 16px 0px 42px;
  border: none;
  border-radius: 4px;
  /* box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25); */
  color: #444;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
  border: 1px solid #d2d2d2 !important;
  width: 300px;
}


.login-btn-microsoftbutton:active {
  background-color: #eee;
}

.login-btn-microsoftbutton:disabled {
  filter: grayscale(100%);
  background-color: #ebebeb;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  cursor: not-allowed;
}


.Val-btn {
  width: 300px !important;
  height: 44px !important;
  background-color: #36a41d !important;
  color: white;
  border: 1px solid #36a41d !important;
  border-radius: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.Otp-title {
  font-family: "Rubik";
  font-weight: 600;
  font-size: 20px;
}

.eemail {
  font-family: "Roboto" !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #3c3c3c !important;
}

.resendotp-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.otp-text {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 400;
  line-height: 14.06px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #818181;
  margin-bottom: 8px;

}

.resend-link {
  font-family: "Roboto";
  font-size: 12px;
  font-weight: 500;
  line-height: 14.06px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #36a41d;
}